import { Model } from '@vuex-orm/core'

class TaxService extends Model {
  static entity = 'taxservice';

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.edsKeyVerify = function (payload) {
        return this.post(Model.$routes.taxservice.edsKeyVerify(), payload, { save: false })
      }

      return configActions
    }
  }
}

export default TaxService
