import Base64js from 'base64-js'
import { get } from 'lodash'
import { addSignHeaderDp } from '~/services/Tax/utils/utils'
import sfsCert1 from '~/assets/data/EK_C_NEW_2023.cer.unit'

export class TaxReportService {
  _signProvider = null

  userCert = null

  /**
   * for now you can pass ref to vue component like m-dialog-iit-sign-plugin or m-iit-sign-plugin which can sign and crypt data using user key
   * @param provider
   */
  constructor (provider, fallbackMethod) {
    this._provider = provider
    this._fallbackMethod = fallbackMethod
    this.applyUserCert()
  }

  applyUserCert () {
    this.userCert = get(this._provider, 'keyData[0]', null)
  }

  get userCertData () {
    return get(this.userCert, 'data', null)
  }

  getHKBOSField ({ sentry, organization, type, entity } = {}) {
    const hkbos = get(this.userCert, 'infoEx.subjDRFOCode', null) ||
      get(this.userCert, 'infoEx.subjEDRPOUCode', null) ||
      get(organization, 'edrpou', null)
    if (!hkbos && sentry) {
      sentry.captureException(new Error(JSON.stringify({ type, entity, cert: JSON.stringify(get(this.userCert, 'infoEx')) })))
    }
    return hkbos
  }

  /**
   * @param xmlDocument - Uint8Array
   * @param isXml - Boolean
   * @param userCert - Uint8Array
   */
  async getEnvelopedData (xmlDocument, isXml = true, userCert) {
    const isDiia = this._provider?.isDiia
    const title = isXml ? 'Підпис документу для відправки в ДПС' : 'Підпис отриманих даних від ДПС'
    const signedXml = await this._provider.sign(xmlDocument, {
      title,
      fallbackMethod: this._fallbackMethod
    }) // xml sign in base64
    if (!signedXml) {
      return null
    }
    const signedXmlContainer = Base64js.toByteArray(addSignHeaderDp(signedXml, 'UA1_SIGN\0')) // xml sign container in Unit8Array
    // crypt data
    const envelopedData = await this._provider.envelopData([sfsCert1], signedXmlContainer) // base64 string
    if (!envelopedData) {
      return null
    }
    const envelopedDataContainer = Base64js.toByteArray(addSignHeaderDp(envelopedData, 'UA1_CRYPT\0')) // Unit8Array

    if (isDiia && this._provider?.endUser) {
      this.userCert = await this._provider.endUser.GetSigner(Base64js.toByteArray(signedXml), 0)
    }
    const userCertContainer = Base64js.toByteArray(addSignHeaderDp(Base64js.fromByteArray(userCert || this.userCertData), 'CERTCRYPT\0')) // user certificate container in Unit8Array
    const concatenatedData = new Uint8Array(userCertContainer.length + envelopedDataContainer.length) // Unit8Array
    concatenatedData.set(userCertContainer)
    concatenatedData.set(envelopedDataContainer, userCertContainer.length)
    const signedConcatenatedData = await this._provider.sign(concatenatedData, {
      title: 'Підпис шифрованих даних',
      fallbackMethod: this._fallbackMethod
    }) // sign in base64
    if (!signedConcatenatedData) {
      return null
    }
    return addSignHeaderDp(signedConcatenatedData, 'UA1_SIGN\0') // base64
  }
}
