<template lang="pug">
  div(class="dialog")
    div(class="dialog__left")
      e-overlay-block(:loading="loading" progress-size="xl" progress-width="5" color="#161b25" opacity="0.3")
      div
        span(v-if="currentStep" class="dialog__stepper mb-2") {{ $t('Step') }} {{ currentStep }} / {{ maxStep }}
        h3(v-if="title" class="dialog__title mb-3 pt-0")
          //v-btn(
          //  v-if="showBackBtn"
          //  class="back-arrow"
          //  @click="handleStepBack"
          //  icon
          //  small
          //)
          //  e-svg-icon(size="sm") arrow-next
          span {{ $t(title) }}
      div(class="dialog__left-content")
        div(
          v-if="showHint && xsDevice"
          class="mb-6"
        )
          block-rozetkapay-hint(
            :currentStep="step"
            :steps="steps"
            mobile
          )
        block-rozetkapay-preview-info(v-if="step === steps.preview")
        block-rozetkapay-phone-verification(
          v-if="step === steps.phoneVerification"
          @data-verified="handleDataVerified"
          @frame-loaded="loading = false"
        )
        block-rozetkapay-user-form(
          v-if="step === steps.form"
          :data="personData"
          ref="form"
          @change:maxStep="maxStep = $event"
        )
        block-rozetkapay-client-documents(
          v-if="step === steps.clientDocuments"
          ref="clientDocuments"
          :order="order"
          :show-terms="!financialDocumentsRequired"
        )
        block-rozetkapay-financial-documents(
          v-if="step === steps.financialDocuments"
          ref="financialDocuments"
          :order="order"
        )
        block-rozetkapay-sign-info(
          v-if="step === steps.signInfo"
          :order="order"
        )
        block-rozetkapay-order-sent-info(v-if="step === steps.orderSentInfo")
      div(
        v-if="!hideActions"
        class="dialog__actions"
      )
        v-btn(
          class="main-button"
          :class="{ 'w-100': xsDevice }"
          @click="handleClick"
          :loading="btnLoading"
        ) {{ $t(btnText) }}
    div(v-if="!xsDevice && showHint" class="dialog__right")
      block-rozetkapay-hint(
        :currentStep="step"
        :steps="steps"
      )
</template>

<script>
import responsive from '~/mixins/pages/responsive'
import BlockRozetkapayPreviewInfo from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-preview-info.vue'
import BlockRozetkapayPhoneVerification
  from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-phone-verification.vue'
import BlockRozetkapayUserForm from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-user-form.vue'
import EOverlayBlock from '~/components/elements/overlays/e-overlay-block.vue'
import ESvgIcon from '~/components/elements/icons/e-svg-icon.vue'
import BlockRozetkapayHint from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-hint'
import BlockRozetkapayClientDocuments from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-client-documents'
import BlockRozetkapayFinancialDocuments from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-financial-documents'
import BlockRozetkapaySignInfo from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-sign-info'
import BlockRozetkapayOrderSentInfo from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-order-sent-info'
import rozetkaPay from '~/modules/acquiring/mixins/rozetkaPay'

export default {
  name: 'BlockRozetkapayConnecting',
  components: {
    BlockRozetkapayHint,
    EOverlayBlock,
    ESvgIcon,
    BlockRozetkapayUserForm,
    BlockRozetkapayPhoneVerification,
    BlockRozetkapayPreviewInfo,
    BlockRozetkapayClientDocuments,
    BlockRozetkapayFinancialDocuments,
    BlockRozetkapaySignInfo,
    BlockRozetkapayOrderSentInfo
  },
  mixins: [responsive, rozetkaPay],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    changeDialogWidth: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    step: 'preview',
    loading: false,
    btnLoading: false,
    personData: null,
    title: 'Apply online',
    showBackBtn: false,
    hideActions: false,
    currentStep: null,
    signLink: '',
    clientDocumentsRef: null,
    maxStep: 2
  }),
  computed: {
    showHint () {
      return this.step !== this.steps.phoneVerification && this.step !== this.steps.orderSentInfo
    },
    order () {
      return this.model.query().first()
    },
    orderId () {
      return this._.get(this.order, 'id')
    },
    btnText () {
      if (this.step === this.steps.signInfo) {
        return 'Sign'
      } else if (this.step === this.steps.orderSentInfo) {
        return 'Clear'
      }
      return 'Continue'
    },
    financialDocumentsRequired () {
      return this._.get(this.personData, 'financial_data.planed_financial_turnover') === '> 400.000'
    }
  },
  created () {
    this.checkStatus()
  },
  methods: {
    close () {
      this.changeDialogWidth('900px')
      this.closeModal()
    },
    fillPersonData () {
      this.personData = JSON.parse(JSON.stringify(this._.get(this.order, 'personData')))
      const phoneNumber = this._.get(this.personData, 'contact_details.phone_number')
      const reportEmail = this._.get(this.personData, 'company_details.report_email')
      const email = this._.get(this.personData, 'contact_details.email')
      const orgEmail = this._.get(this.$Organization, 'owner.email')
      // eslint-disable-next-line camelcase
      if (this.personData?.contact_details) {
        this.personData.contact_details.phone_number = phoneNumber || this._.get(this.order, 'phone')
        this.personData.contact_details.email = email || orgEmail
      }
      // eslint-disable-next-line camelcase
      if (this.personData?.company_details) {
        this.personData.company_details.report_email = reportEmail || orgEmail
      }
    },
    async checkStatus () {
      try {
        this.loading = true
        await this.model.api().all()
        if (this.order) {
          const status = this._.get(this.order, 'orderStatus')
          this.fillPersonData()
          switch (status) {
            case this.model.STATUSES.orderCreated:
              this.goToForm()
              break
            case this.model.STATUSES.questionarySend:
              this.goToDocuments()
              break
            case this.model.STATUSES.docsSaved:
              await this.goToSignInfo()
              break
            case this.model.STATUSES.signed:
              this.goToOrderSentInfo()
              break
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    goToDocuments () {
      const documents = this._.get(this.order, 'documents')
      const clientDocuments = this._.filter(documents, i => i?.sectionName === 'client_documents')
      if (clientDocuments?.length) {
        return this.goToFinancialDocuments()
      }
      return this.goToClientDocuments()
    },
    handleStepBack () {
      switch (this.step) {
        case this.steps.financialDocuments:
          this.goToClientDocuments()
          break
        case this.steps.clientDocuments:
          this.goToForm()
          break
      }
    },
    handleClick () {
      switch (this.step) {
        case this.steps.preview:
          this.goToPhoneVerification()
          break
        case this.steps.form:
          this.submitFormData()
          break
        case this.steps.clientDocuments:
          this.submitClientDocuments()
          break
        case this.steps.financialDocuments:
          this.submitFinancialDocuments()
          break
        case this.steps.signInfo:
          this.handleSign()
          break
        case this.steps.orderSentInfo:
          this.close()
          break
      }
    },
    goToPhoneVerification () {
      this.changeDialogWidth('710px')
      this.currentStep = null
      this.title = null
      this.showBackBtn = false
      this.personData = null
      this.step = this.steps.phoneVerification
      this.loading = true
      this.hideActions = true
    },
    goToForm () {
      this.changeDialogWidth('900px')
      this.currentStep = 1
      this.title = 'Enter information about the company'
      this.showBackBtn = false
      this.step = this.steps.form
      this.hideActions = false
    },
    goToClientDocuments () {
      this.changeDialogWidth('900px')
      this.currentStep = 2
      this.title = 'Upload client documents'
      this.showBackBtn = true
      this.step = this.steps.clientDocuments
      this.hideActions = false
    },
    goToFinancialDocuments () {
      this.changeDialogWidth('900px')
      this.currentStep = 3
      this.title = 'Upload financial documents'
      this.showBackBtn = true
      this.step = this.steps.financialDocuments
      this.hideActions = false
    },
    async goToSignInfo () {
      try {
        this.btnLoading = true
        this.changeDialogWidth('900px')
        this.currentStep = null
        this.title = 'The application has been successfully created'
        this.showBackBtn = false
        this.step = this.steps.signInfo
        this.hideActions = false
        if (!this.orderId) {
          return
        }
        this.signLink = this._.get(await this.model.api().getSignLink(this.orderId), 'response.data.link')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.btnLoading = false
      }
    },
    goToOrderSentInfo () {
      this.changeDialogWidth('550px')
      this.currentStep = null
      this.title = null
      this.showBackBtn = false
      this.step = this.steps.orderSentInfo
      this.hideActions = false
    },
    async handleSign () {
      window.open(this.signLink, '_blank')
      if (this._.isFunction(this.tableRequest)) {
        await this.tableRequest()
      }
      this.close()
    },
    async submitFormData () {
      try {
        this.btnLoading = true
        const formRef = this.$refs?.form
        if (formRef) {
          const valid = await formRef?.$refs?.form?.validate()
          if (!valid || !this.orderId) {
            return
          }
          this.personData = formRef?.form
          const payload = JSON.parse(JSON.stringify(this.personData))
          const phoneNumber = this._.get(payload, 'contact_details.phone_number')
          if (phoneNumber) {
            payload.contact_details.phone_number = phoneNumber.replace(/-/g, '')
          }
          const futureFinancialStatus = this._.get(payload, 'financial_data.future_financial_status')
          if (futureFinancialStatus) {
            payload.financial_data.future_financial_status = parseFloat(futureFinancialStatus)
          } else {
            delete payload.financial_data.future_financial_status
          }
          const licenseNumbers = this._.get(payload, 'company_details.license_numbers')
          if (typeof licenseNumbers === 'string') {
            payload.company_details.license_numbers = licenseNumbers.split(',')
          }
          await this.model.api().updatePersonData(this.orderId, { personData: payload })
          this.goToClientDocuments()
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.btnLoading = false
      }
    },
    async submitClientDocuments () {
      try {
        this.btnLoading = true
        const termsRef = this._.get(this.$refs, 'clientDocuments.$refs.termsRef')
        if (termsRef) {
          const { valid } = await termsRef.validate()
          if (!valid) {
            return
          }
        }
        const clientDocumentsRef = this.$refs.clientDocuments
        const valid = await this.validateDocuments(clientDocumentsRef)
        if (!valid) {
          return false
        }
        if (this.financialDocumentsRequired) {
          this.clientDocumentsRef = clientDocumentsRef
          this.goToFinancialDocuments()
        } else {
          const success = await this.submitDocuments(clientDocumentsRef, 'client_documents')
          if (success) {
            await this.goToSignInfo()
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.btnLoading = false
      }
    },
    async submitFinancialDocuments () {
      try {
        this.btnLoading = true
        const termsRef = this._.get(this.$refs, 'financialDocuments.$refs.termsRef')
        if (termsRef) {
          const { valid } = await termsRef.validate()
          if (!valid) {
            return
          }
        }
        const financialDocumentsRef = this.$refs.financialDocuments
        const valid = await this.validateDocuments(financialDocumentsRef)
        if (!valid) {
          return false
        }
        const successClientDocs = await this.submitDocuments(this.clientDocumentsRef, 'client_documents')
        const successFinDocs = await this.submitDocuments(financialDocumentsRef, 'financial_documents')
        if (successClientDocs && successFinDocs) {
          await this.goToSignInfo()
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.btnLoading = false
      }
    },
    async submitDocuments (ref, sectionName) {
      if (!this.orderId) {
        return false
      }
      const files = ref?.files || {}
      const objectKeys = Object.keys(files)
      for (let i = 0; i < objectKeys.length; i++) {
        const key = objectKeys[i]
        const arr = files[key]
        for (let j = 0; j < arr.length; j++) {
          const file = arr[j]
          const formData = new FormData()
          formData.append('file', file)
          formData.append('sectionName', sectionName)
          formData.append('accountOrderId', this.orderId)
          formData.append('fieldName', key.replace(/[A-Z]/g, l => `_${l.toLowerCase()}`))
          formData.append('name', file.name)
          await this.model.api().documents(this.orderId, formData)
        }
      }
      return true
    },
    async validateDocuments (ref) {
      if (ref) {
        let validation = true
        const validationErrors = {}
        for (let i = 0; i < Object.keys(ref.$refs).length; i++) {
          const key = Object.keys(ref.$refs)[i]
          const inputRef = ref.$refs[key]?.$refs?.fileInput
          if (inputRef?.validate) {
            const { valid, errors } = await inputRef.validate()
            if (!valid) {
              validation = false
            }
            validationErrors[key] = errors
          }
        }
        ref.errors = validationErrors
        return validation
      }
      return false
    },
    async handleDataVerified ({ id, phone } = {}) {
      const payload = { rid: id, phone }
      try {
        this.loading = true
        await this.model.api().create(payload)
        this.fillPersonData()
        this.goToForm()
      } catch (e) {
        this.$handlers.error(e, this)
        this.close()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  position: relative;
  background: #fff;
  display: flex;
  height: 100%;

  .back-arrow {
    transform: rotate(180deg);
    margin-right: 5px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: static;
  }

  &__left-content {
    flex-grow: 1;
    overflow-y: auto;
    margin-right: -15px;
    padding-right: 15px;
  }

  &__actions {
    padding: 32px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-direction: column;
      padding-top: 16px;
    }
  }
}
</style>
