import Dialog from '~/models/system/Dialog'
import EdsIitCheckbox from '~/modules/eds-iit-checkbox/models/EdsIitCheckbox'
import edsIitCheckboxHelper from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxHelper'
import contentDialogTop from '~/mixins/dialogs/contentDialogTop'
import TaxService from '~/modules/tax-service/models/TaxService'

const edsIitCheckboxDialog = {
  mixins: [edsIitCheckboxHelper, contentDialogTop],
  methods: {
    async edsKeyVerify ({ edsKeyData, sign, fallbackMethod, dialogType = 'edsIitCheckboxForOrganization' } = {}) {
      const modelInstance = this.getEdsIitCheckboxModel(dialogType)
      const EDRPOUCode = this._.get(edsKeyData, 'edrpouCode')
      const DRFOCode = this._.get(edsKeyData, 'drfoCode')
      const dataToSign = EDRPOUCode || DRFOCode
      if (!dataToSign || typeof sign !== 'function' || !modelInstance?.verifyKey) {
        return true
      }
      try {
        const signedData = await sign(dataToSign, { fallbackMethod })
        if (!signedData) {
          return true
        }
        const payload = {
          EDRPOUCode,
          DRFOCode,
          sign: signedData
        }
        const res = await TaxService.api().edsKeyVerify(payload)
        const publicKeyId = this._.get(res, 'response.data[0].publicKeyId')
        const keyDataPublicKeyId = (edsKeyData.publicKeyId || '').toLowerCase().replace(/\s/g, '')
        if (publicKeyId !== keyDataPublicKeyId) {
          const confirmed = await this.contentDialogTop.open({
            component: 'block-key-verification-warning',
            width: '500px',
            hideCloseButton: true
          })
          if (!confirmed) {
            await this.closeEdsIitCheckboxDialog(dialogType)
            await fallbackMethod()
            return false
          } else {
            return true
          }
        } else {
          await modelInstance.saveModelData({
            verifyKey: false
          })
          return true
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    getEdsIitCheckboxDialog () {
      return Dialog.query().where('type', 'edsIitCheckboxDialog').first()
    },
    getEdsIitCheckboxModel (type) {
      return EdsIitCheckbox.query().where('type', type).first()
    },
    getEdsIitCheckboxInterface (type) {
      const modelInstance = this.getEdsIitCheckboxModel(type)
      return modelInstance ? modelInstance.getInterface() : null
    },
    async closeEdsIitCheckboxDialog (type, hide = false) {
      const modelInstance = this.getEdsIitCheckboxModel(type)
      if (modelInstance && !hide) {
        await modelInstance.delete()
      }
      const dialog = this.getEdsIitCheckboxDialog()
      if (dialog) {
        await dialog.close()
      }
    },
    async handleOnConfirmEdsIitCheckboxDialog (params = {}) {
      const {
        onConfirm,
        type,
        signInterface,
        fallbackMethod,
        dialog,
        keyCheck,
        checkAuthService,
        createReportService
      } = params
      if (typeof onConfirm === 'function') {
        const data = await this.generateSignInterfacePayloadAndErrorsCheck({
          type,
          keyCheck,
          checkAuthService,
          createReportService,
          signInterface,
          fallbackMethod: async () => {
            if (typeof fallbackMethod === 'function') {
              await fallbackMethod()
            }
          },
          errorFallback: async () => {
            if (dialog) {
              await dialog.close()
            }
          }
        })
        await onConfirm(data)
      }
    },
    async useEdsIitCheckboxDialog ({
      type = 'edsIitCheckboxForOrganization',
      method = 'use',
      fallbackMethod,
      checkAuthService = false,
      createReportService = false,
      keyCheck = true,
      onlyFileSign = false,
      pluginTitles = {},
      hints = {},
      dialogParams = {},
      onConfirm
    } = {}) {
      const dialog = this.getEdsIitCheckboxDialog()
      const signInterface = await dialog.use({
        open: method === 'open',
        pluginType: type,
        pluginTitles,
        hints,
        onlyFileSign,
        ...(dialogParams || {}),
        onConfirm: async (signInterface) => {
          await this.handleOnConfirmEdsIitCheckboxDialog({
            onConfirm,
            type,
            signInterface,
            fallbackMethod,
            dialog,
            keyCheck,
            checkAuthService,
            createReportService
          })
        }
      })
      if (signInterface) {
        await this.handleOnConfirmEdsIitCheckboxDialog({
          onConfirm,
          type,
          signInterface,
          fallbackMethod,
          dialog,
          keyCheck,
          checkAuthService,
          createReportService
        })
      }
    }
  }
}
export default edsIitCheckboxDialog
