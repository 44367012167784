<template lang="pug">
  div(class="error-state")
    img(
      class="error-state__illustration"
      src="~/assets/images/racoons/racoon-idea.svg"
      alt="Raccoon"
    )

    div(class="error-state__content")
      div(class="error-state__text-wrapper")
        h2(class="error-state__title") {{ title }}
        p(class="error-state__description") {{ description }}

      div(class="error-state__button-wrapper")
        v-btn(
          class="error-state__button main-button"
          @click="closeErrorState"
        ) Добре
</template>

<script>
import { OperationType } from '~/modules/goods/views/import-export-flow/const/operationType'

export default {
  name: 'MErrorState',
  props: {
    operationType: {
      type: String,
      required: true
    },
    closeModal: {
      type: Function,
      default: null
    },
    isFileValidationError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return this.$t('Oops, something went wrong')
    },
    description () {
      if (this.isFileValidationError) {
        return this.$t('The file contains invalid data. Please check the file and try again.')
      }
      switch (this.operationType) {
        case OperationType.UPLOAD:
          return this.$t('The previous import operation has not yet been completed. Please wait for it to complete and try again.')
        case OperationType.EXPORT:
          return this.$t('The previous export operation has not yet been completed. Please wait for it to complete and try again.')
        case OperationType.DELETE:
          return this.$t('The previous deletion operation has not yet been completed. Please wait for it to complete and try again.')
        default:
          return this.$t('The previous operation has not yet been completed. Please wait for it to complete and try again.')
      }
    }
  },
  methods: {
    closeErrorState () {
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.error-state {
  display: flex;
  flex-direction: column;

  &__illustration {
    width: 200px;
    height: 200px;
    align-self: center;
    margin-bottom: 20px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      width: 106px;
      height: 106px;
    }
  }

  &__content {
    width: 100%;
  }

  &__text-wrapper {
    margin-bottom: 20px;
  }

  &__title {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
    color: #161B25;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__description {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    color: #555;
    margin: 10px 0 20px 0;
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 50px;
  }

  &__button {
    width: 200px;
    height: 48px;
    align-self: flex-end;
    @media (max-width: map-get($breakpoints, 'sm')) {
      width: 100%;
      height: 48px !important;
    }
  }
}
</style>
