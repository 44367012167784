<template lang="pug">
  div(class="block-acquiring-bank-selection")
    div(class="block-acquiring-bank-selection__cards-wrap")
      template(v-for="item in availableBanks")
        div(
          v-if="isVisible(_.find(banks, bank => bank.key === item.key))"
          :key="item.key"
          class="block-acquiring-bank-selection__card"
          :class="{ selected: selectedBank === item.key }"
          @click="cardClick(item)"
        )
          div(class="block-acquiring-bank-selection__card-header")
            img(
              v-if="item.logo"
              :src="require(`~/assets/images/icons/${item.logo}`)"
              alt="logo"
            )
          div(class="block-acquiring-bank-selection__card-content")
            div(class="mb-3")
              p(class="block-acquiring-bank-selection__card-title") {{ $t(item.titleFirst) }}
              p(class="block-acquiring-bank-selection__card-text") {{ $t(item.textFirst) }}
            div()
              p(class="block-acquiring-bank-selection__card-title") {{ $t(item.titleSecond) }}
              p(class="block-acquiring-bank-selection__card-text") {{ $t(item.textSecond) }}
    div(class="d-flex justify-end")
      v-btn(
        class="main-button block-acquiring-bank-selection__action"
        :class="{ 'w-100': isResponsive }"
        @click="btnClick"
      ) {{ $t('Continue') }}
</template>

<script>
export default {
  name: 'MBlockAcquiringBankSelection',
  props: {
    banks: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selectedBank: null
  }),
  computed: {
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    },
    banksCards () {
      return [
        {
          key: 'mono',
          logo: 'monobank-logo.svg',
          titleFirst: '1.3%',
          textFirst: 'Комісія від суми транзакцій'
        },
        {
          key: 'easyPay',
          logo: 'easypay-logo.svg',
          titleFirst: '1.3%',
          textFirst: 'Комісія від суми транзакцій',
          titleSecond: 'Оренда терміналу',
          textSecond: 'Безкоштовно'
        },
        {
          key: 'rozetkaPay',
          logo: 'rozetka-pay-logo.svg',
          titleFirst: '1.3%',
          textFirst: 'Комісія від суми транзакцій',
          textSecond: 'При підключенні з 01.07 по 31.08.24, комісія після акції - 1,5%'
        },
        {
          key: 'oschad',
          logo: 'oschadbank-logo.svg',
          titleFirst: '1.3%',
          textFirst: 'Комісія від суми транзакцій',
          titleSecond: 'Оренда терміналу',
          textSecond: 'Безкоштовно'
        },
        {
          key: 'aBank',
          logo: 'a-bank-logo.svg',
          percent: '1.7%',
          percentText: 'Комісія від суми транзакцій за Android POS (з моб. додатком Checkbox)',
          price: '500 грн/міс',
          priceText: 'Вартість оренди Android POS'
        }
        // {
        //   key: 'privat',
        //   logo: 'privatbank-logo.svg',
        //   titleFirst: '1.3%',
        //   textFirst: 'Комісія від суми транзакцій'
        // }
      ]
    },
    availableBanks () {
      const bankKeys = this._.map(this.banks, bank => bank.key)
      return this._.filter(this.banksCards, banksCard => bankKeys.includes(banksCard.key))
    }
  },
  methods: {
    isVisible (item) {
      return this._.isFunction(item.visible) ? item.visible(item) : true
    },
    cardClick (item) {
      this.selectedBank = item.key
    },
    btnClick () {
      this.$emit('changeSelectedBank', this.selectedBank)
    }
  }
}
</script>

<style scoped lang="scss">
.block-acquiring-bank-selection {
  &__cards-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding-bottom: 32px;
  }

  &__card {
    border: 1px solid #D0D5DD;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    flex-basis: 100%;

    &.selected {
      border: 1px solid transparent;
      box-shadow: 0 0 0 2px #161B25;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    @media (min-width: map-get($breakpoints, 'sm')) {
      flex-basis: calc(33.33% - 8px);
    }
  }

  &__card-header {
    height: 80px;
    background: #F0F1F2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    position: relative;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__card-content {
    padding: 20px;
    background: #fff;
  }

  &__card-title {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    margin: 0;
  }

  &__card-text {
    font-size: 12px;
    font-weight: 300;
    color: #000;
    margin: 0;
  }

  &__card-tooltip {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }
}
</style>
