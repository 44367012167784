<template lang="pug">
  div(class="privatbank-settings-form")
    div(
      v-if="tokenLoading"
      class="privatbank-settings-form__loader"
    )
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
    v-form(
      @submit.prevent.stop="submit"
      v-else
    )
      ValidationObserver(ref="form" slim)
        t-orm-fields(v-bind.sync="form" :items="fields")
      div(class="privatbank-settings-form__actions")
        v-btn(
          type="submit"
          :loading="loading"
          class="main-button"
        ) {{ $t('Save') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import CashRegisters from '~/models/directories/CashRegisters'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'
import Privatbank from '~/modules/acquiring/models/Privatbank'
import PrivatbankToken from '~/modules/acquiring/models/PrivatbankToken'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'

export default {
  name: 'BlockPrivatbankSettingsForm',
  components: {
    TOrmFields,
    EProgressCircular
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    },
    item: {
      type: Object,
      default: null
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      tokenLoading: false,
      form: {
        privatbankTerminalToken: null,
        cashRegister: null,
        isActive: null
      }
    }
  },
  computed: {
    model () {
      return Privatbank
    },
    tokenModel () {
      return PrivatbankToken
    },
    fields () {
      return [
        {
          model: 'privatbankTerminalToken',
          component: 'v-text-field',
          provider: {
            vid: 'privatbankTerminalToken',
            name: 'Privatbank token',
            rules: 'required|max:69'
          },
          attrs: {
            label: 'Privatbank token',
            outlined: true
          },
          fieldVal: () => this._.get(this.privatbankToken, 'clientTokenEncrypted', null)
        },
        new AutocompleteBuilder({
          model: 'cashRegister',
          label: 'Connected to cash register',
          rules: 'required',
          query: model => model.api().filter({ mode: CashRegisters.itemModes.checkbox.mode }),
          asyncDefault: (items) => {
            if (!this._.get(this.item, 'cashRegister', null) && items && items.length === 1) {
              return items[0]
            }
            const cashRegisterOnEdit = this._.get(this.item, 'cashRegister', null)
            if (cashRegisterOnEdit) {
              return cashRegisterOnEdit
            }
          },
          itemValue: item => this._.get(item, 'id', null)
        }, CashRegistersSearch).build(),
        {
          model: 'isActive',
          component: 'v-switch',
          provider: {
            vid: 'isActive',
            name: 'Connect'
          },
          attrs: {
            label: 'Connect'
          },
          fieldVal: () => this._.get(this.item, 'isActive', true)
        }
      ]
    },
    privatbankToken () {
      return this.tokenModel.query().first()
    }
  },
  created () {
    this.getToken()
  },
  methods: {
    async getToken () {
      try {
        this.tokenLoading = true
        await this.tokenModel.api().all()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.tokenLoading = false
      }
    },
    async createOrUpdateToken () {
      const savedToken = this._.get(this.privatbankToken, 'clientTokenEncrypted')
      const nextToken = this._.get(this.form, 'privatbankTerminalToken')
      const payload = {
        clientTokenEncrypted: this.form.privatbankTerminalToken
      }
      if (savedToken && (savedToken !== nextToken)) {
        await this.tokenModel.api().update(this.privatbankToken, payload)
      } else if (!savedToken && nextToken) {
        await this.tokenModel.api().create(payload)
      }
    },
    async submit () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      try {
        this.loading = true
        const payload = {
          ...this.form,
          cashRegister: `/cash-registers/${this._.get(this.form, 'cashRegister.id', '')}`
        }
        await this.createOrUpdateToken()
        if (this.isEditing) {
          delete payload.privatbankTerminalToken
          await this.model.api().update(this.item, payload)
        } else {
          payload.privatbankTerminalToken = `/privatbank-terminal-tokens/${this._.get(this.privatbankToken, 'id')}`
          await this.model.api().create(payload)
        }
        await this.tableRequest()
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.privatbank-settings-form {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
  }
}
</style>
