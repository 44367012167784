import moment from 'moment-timezone'
import { Model } from '@vuex-orm/core'
import { get, map } from 'lodash'
import Employees from '~/modules/employees/models/Employees'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import FilterAble from '~/models/mixins/FilterAble'
import CashRegisters from '~/models/directories/CashRegisters'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import Shifts from '~/models/directories/Shifts'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'
import Outlets from '~/models/directories/Outlets'
import OutletsSearch from '~/models/directories/search/OutletsSearch'
import { formats } from '~/const/global'
import Dialog from '~/models/system/Dialog'
import SmsService from '~/services/Receipt/SmsService'
import AppNotifications from '~/services/Notifications/AppNotifications'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'

export class Receipts extends ChainInheritance(Directory, [
  FilterAble,
  PersistAble,
  TimestampAble,
  AssignAble]) {
  static entity = 'receipts'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'fiscalDate'
  static forceFiltered = true
  static persistOptions = {
    insertOrUpdate: [Employees.entity, CashRegisters.entity]
  }

  static statusesMap = { CREATED: true, CLOSED: false }

  static processingStatuses = {
    CREATED: 'CREATED',
    DONE: 'DONE',
    ERROR: 'ERROR'
  }

  static TYPES = {
    SELL: 'SELL',
    RETURN: 'RETURN',
    SERVICE_IN: 'SERVICE_IN',
    SERVICE_OUT: 'SERVICE_OUT',
    SERVICE_CURRENCY: 'SERVICE_CURRENCY',
    CURRENCY_EXCHANGE: 'CURRENCY_EXCHANGE'
  }

  static mobileFieldIcons = {
    SELL: 'arrow-inside-circle-blue',
    RETURN: 'arrow-outside-circle-red',
    SERVICE_IN: 'arrow-up-blue',
    SERVICE_OUT: 'arrow-down-red'
  }

  static fields () {
    return {
      shift_id: this.attr(null),
      cash_register_id: this.attr(null),

      id: this.attr(null),
      fiscalDate: this.attr(null),
      deliveredAt: this.attr(null),
      type: this.attr(null),
      serial: this.attr(null),
      status: this.attr(null),
      fiscalCode: this.attr(),
      transaction: this.attr(null),
      taxes: this.attr(null),
      paymentType: this.attr(null),
      roundSum: this.attr(null),
      totalSum: this.attr(null),
      totalPayment: this.attr(null),
      totalRest: this.attr(null),
      amount: this.attr(null),
      income: this.attr(null),
      outcome: this.attr(null),
      incomeCount: this.attr(null),
      outcomeCount: this.attr(null),
      date: this.attr(null),
      shift: this.belongsTo(Shifts, 'shift_id'),
      cashRegister: this.belongsTo(CashRegisters, 'cash_register_id'),
      prePaymentRelationId: this.attr(null)
    }
  }

  get employee () {
    return this.shift.cashier
  }

  static ormRelationMap = [
    // 'group',
    'shifts'
  ]

  static ormTrans = {
    single: 'Receipt',
    multy: 'Receipts',
    notificationSingle: 'Receipt ',
    pageTitle: 'Receipts archive'
  }

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['status', 'updatedAt'],
      'sort-desc': [true, true]
    })
  }

  static ormFilters = [
    {
      model: 'organizations',
      component: 'e-input-organizations-select',
      attrs: {
        visible: (e, ctx) => ctx?.$User?.globalAllOrganizationsSelected,
        hideDetails: true
      }
    },
    {
      model: 'type',
      component: 'v-select',
      attrs: {
        items: [
          { text: 'Продаж', value: Receipts.TYPES.SELL },
          { text: 'Повернення', value: Receipts.TYPES.RETURN },
          { text: 'Службове внесення', value: Receipts.TYPES.SERVICE_IN },
          { text: 'Службова видача', value: Receipts.TYPES.SERVICE_OUT }
        ],
        label: 'Type',
        outlined: true,
        clearable: true,
        hideDetails: true,
        menuProps: { offsetY: true }
      },
      fieldVal: () => ''
    },
    {
      model: 'fiscalDate',
      type: 'dateRange',
      component: 'e-input-datetime-range',
      default: () => {
        const today = this.$moment(new Date()).format(formats.dateISO8601)
        return [today, today]
      },
      attrs: {
        clearable: false,
        chooseOnClose: true,
        emmitOnlyOnSelectClicked: true,
        type: 'date',
        range: true,
        'hide-details': true,
        outlined: true,
        label: 'Fiscal date',
        min: (dates) => {
          if (dates && dates.length === 1) {
            const date = new Date(dates[0])
            date.setMonth(date.getMonth() - 2)
            return this.$moment(date).format(formats.dateISO8601)
          }
        },
        max: (dates) => {
          const today = new Date()
          const formattedToday = this.$moment(today).format(formats.dateISO8601)
          if (dates && dates.length === 1) {
            const date = new Date(dates[0])
            date.setMonth(date.getMonth() + 2)
            if (date.getTime() > today.getTime()) {
              return formattedToday
            }
            return this.$moment(date).format(formats.dateISO8601)
          }
          return formattedToday
        },
        fastDates: true,
        filterFastDates: (dates) => {
          return dates.filter((item) => {
            const val = item.value
            return val !== 'firstQuarter' && val !== 'secondQuarter' && val !== 'thirdQuarter' && val !== 'fourthQuarter' && val !== 'currentQuarter' && val !== 'currentYear'
          })
        }
      }
    },
    new AutocompleteBuilder({
      model: 'cashRegister',
      label: 'Cash register ',
      emmitEmptyObject: true,
      autoWidth: true,
      emitSearch: false,
      itemClass: 'ws-pre',
      hideDetails: true,
      watchProps: ['ctx.attrs.organizations'],
      onWatchProps: async (ctx, val, prevVal) => {
        if (JSON.stringify(val) !== JSON.stringify(prevVal)) {
          await ctx.requesting()
        }
      },
      customQuery: ctx => ctx?.$User?.globalAllOrganizationsSelected,
      query: (model, ctx, config, user) => {
        const filter = { 'mode[in]': `${CashRegisters.itemModes.checkbox.mode},${CashRegisters.itemModes.deactivated.mode},${CashRegisters.itemModes.alert.mode},${CashRegisters.itemModes.deactivatedByUser.mode}` }
        if (!user?.globalAllOrganizationsSelected) {
          return model.api().filter(filter)
        } else {
          let organizations = get(ctx, 'attrs.organizations')
          if (get(organizations, '[0].allOrganizationsItem')) {
            config.params.withAllOrganizations = true
            organizations = []
          } else {
            organizations = map(organizations, i => i?.id)
          }
          return model.api().listMultiAccount({ organizations }, {
            ...config,
            params: {
              ...config.params,
              ...filter
            }
          })
        }
      }
    }, CashRegistersSearch).build(),
    new AutocompleteBuilder({
      model: 'cashRegister.outlet',
      label: 'Outlet  ',
      emmitEmptyObject: true,
      autoWidth: true,
      emitSearch: false,
      hideDetails: true,
      watchProps: ['ctx.attrs.organizations'],
      onWatchProps: async (ctx, val, prevVal) => {
        if (JSON.stringify(val) !== JSON.stringify(prevVal)) {
          await ctx.requesting()
        }
      },
      customQuery: ctx => ctx?.$User?.globalAllOrganizationsSelected,
      query: (model, ctx, config, user) => {
        const filter = { mode: Outlets.itemModes.checkbox.mode }
        if (!user?.globalAllOrganizationsSelected) {
          return model.api().filter(filter)
        } else {
          let organizations = get(ctx, 'attrs.organizations')
          if (get(organizations, '[0].allOrganizationsItem')) {
            config.params.withAllOrganizations = true
            organizations = []
          } else {
            organizations = map(organizations, i => i?.id)
          }
          return model.api().listMultiAccount({ organizations }, {
            ...config,
            params: {
              ...config.params,
              ...filter
            }
          })
        }
      }
    }, OutletsSearch).build(),
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search by receipt number',
        'prepend-inner-icon': 'mdi-magnify',
        visible: (e, ctx) => !ctx.isResponsive
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersMobile = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search by receipt number',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: (user) => {
      const isMultiAccount = user?.globalAllOrganizationsSelected
      const grid = [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: 4,
                sm: 6
              },
              fields: [
                'type'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: 4,
                sm: 6
              },
              fields: [
                'fiscalDate'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: 4,
                sm: 6
              },
              fields: [
                'cashRegister'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: 4,
                sm: 6
              },
              fields: [
                'cashRegister.outlet'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: 4,
                sm: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
      if (isMultiAccount) {
        grid[0].nodes = [
          {
            component: 'v-col',
            attrs: {
              cols: 12,
              md: 4,
              sm: 6
            },
            fields: [
              'organizations'
            ]
          },
          ...grid[0].nodes
        ]
      }
      return { grid }
    }
  }

  get fiscalDateString () {
    return this.getDateTimeProcessing(this.fiscalDate)
  }

  get totalSumString () {
    return (this.totalSum / 100).toFixed(2)
      .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get typeString () {
    return this.fiscalCode && /TEST/.test(this.fiscalCode) ? this.type + '(Test receipt)' : this.type
  }

  static ormHeaders = [
    { text: 'Type', value: 'typeString', sortable: true, filterable: false, sortQuery: 'type' },
    { text: 'Payment type', value: 'paymentType', sortable: true, sortQuery: 'paymentType' },
    { text: 'Date/time', value: 'fiscalDateString', sortable: true, filterable: false, sortQuery: 'fiscalDate' },
    { text: 'Fiscal number', value: 'fiscalCode', sortable: false, filterable: false },
    { text: 'Shift number', value: 'shift.serial', sortable: true, filterable: false, sortQuery: 'shift.serial' },
    { text: 'Total Amount', value: 'totalSumString', width: '120', align: 'right', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormMobileSubtitle = 'typeString'
  static ormMobileTitle = 'fiscalDateString'
  static ormMobileAdditionalText = 'totalSumString'

  static ormColsComponents = {
    fiscalCode: {
      component: 'e-copy-text'
    }
  }

  static ormRowsConfig = {
    disabled: scopedItem => 'status' in scopedItem.item ? scopedItem.item.status === 'CLOSED' : false
  }

  static ormFields = [
    //
  ]

  static ormActions = [
    {
      name: 'openReceiptView',
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        await dialog.open({
          component: 'block-receipt-view',
          width: '470px',
          contentFullHeight: true,
          componentProps: {
            item,
            model: this,
            config: {
              view: 'html',
              download: 'pdf',
              downloadNameField: 'fiscalCode',
              downloadPrefix: 'receipt'
            }
          }
        })
      }
    },
    {
      name: 'sendToEmail',
      text: 'Sent to email',
      icon: {
        type: 'e-svg-icon',
        text: 'send'
      },
      visible: (item, user) => {
        return item.type !== Receipts.TYPES.SERVICE_IN && item.type !== Receipts.TYPES.SERVICE_OUT && !get(user, 'isAccountant')
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'Sent to email',
          width: '500px',
          component: 'm-form-block',
          componentProps: {
            buttonText: 'Send ',
            fields: [
              {
                model: 'email',
                component: 'v-text-field',
                provider: {
                  vid: 'email',
                  name: 'E-mail',
                  rules: 'required|email'
                },
                attrs: {
                  outlined: true,
                  type: 'email',
                  placeholder: 'E-mail'
                },
                cast: val => val && val.toLowerCase()
              }
            ],
            onSubmit: async (data, ctx) => {
              try {
                await Promise.all([
                  Employees.api().filter({ mode: Employees.itemModes.checkbox.mode, limit: 10 }).all(),
                  CashRegisters.api().filter({ mode: CashRegisters.itemModes.checkbox.mode, limit: 1 }).all()
                ])
                const employee = Employees.query().where((item) => {
                  if (item.certificateEnd) {
                    return item.mode === Employees.itemModes.checkbox.mode && new Date().getTime() < new Date(item.certificateEnd).getTime()
                  }
                }).first()
                const accessToken = this._.get(await Employees.api().processingSignin(employee), 'response.data.access_token')
                await ctx.processingApiRequest({
                  request: token => Receipts.api().sendToEmail([data.email], item.id, token),
                  token: accessToken,
                  employee
                })
                AppNotifications.success('The check was successfully sent')
                dialog.close()
              } catch (e) {
                AppNotifications.error(e)
              }
            }
          }
        })
      }
    },
    {
      name: 'sendToSms',
      text: 'Send to sms',
      icon: {
        type: 'e-svg-icon',
        text: 'send'
      },
      visible: (item, user) => {
        const organization = currentOrganizationService.getCurrentOrganization()

        if (organization && organization.enableSmsModule) {
          return item.type !== Receipts.TYPES.SERVICE_IN && item.type !== Receipts.TYPES.SERVICE_OUT && !get(user, 'isAccountant')
        }

        return false
      },
      call: async (item) => {
        await SmsService.openSmsModal(item.id)
      }
    },
    {
      name: 'refundReceipt',
      text: 'Refund receipt',
      icon: {
        type: 'e-svg-icon',
        text: 'selling-2'
      },
      visible: (item, user) => {
        return item.type === Receipts.TYPES.SELL && !get(user, 'isAccountant') && !item.prePaymentRelationId
      },
      call: (item, data, ctx) => {
        // TODO Временное решение (таймзона)
        const datetime = item.fiscalDate
        const date = moment(datetime).toDate()
        let offset = 0
        if (date.toString().includes('GMT+0300')) {
          offset = 3
        } else if (date.toString().includes('GMT+0200')) {
          offset = 2
        } else if (date.toString().includes('GMT+0100')) {
          offset = 1
        }
        date.setHours(Math.round(date.getHours() + offset))
        const fiscalDate = moment(date).format(formats.dateISO8601)
        ctx.$router.push({
          path: '/dashboard/refundreceipt',
          query: {
            search: item.fiscalCode,
            date: fiscalDate
          }
        })
      }
    },
    {
      name: 'cancelReceipt',
      text: 'Cancel receipt',
      icon: {
        type: 'e-svg-icon',
        text: 'decline'
      },
      visible: () => false,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        await dialog.open({
          component: 'block-cancel-receipt',
          width: '600px',
          componentProps: {
            receipt: item
          }
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.processingRead = function (id, token) {
        return this.get(Model.$routes.receipts.processingRead(id),
          {
            save: false,
            headers: { 'X-Processing-Authorization': token }
          }
        )
      }
      configActions.processingCreate = function (payload, token) {
        return this.post(Model.$routes.receipts.processingCreate(), payload, {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.processingCreateService = function (payload, token) {
        return this.post(Model.$routes.receipts.processingCreateService(), payload, {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.processingReadText = function (id) {
        return this.get(Model.$routes.receipts.processingReadText(id), {
          save: false
        })
      }
      configActions.processingReadPng = function (id) {
        return this.get(Model.$routes.receipts.processingReadPng(id), {
          responseType: 'blob',
          save: false
        })
      }
      configActions.processingReadHtml = function (id) {
        return this.get(Model.$routes.receipts.processingReadHtml(id), {
          save: false
        })
      }
      configActions.processingReadPdf = function (id) {
        return this.get(Model.$routes.receipts.processingReadPdf(id), {
          responseType: 'blob',
          save: false
        })
      }
      configActions.processingReadQrCode = function (id) {
        return this.get(Model.$routes.receipts.processingReadQrCode(id), {
          responseType: 'blob',
          save: false
        })
      }
      configActions.readText = function (id) {
        return this.get(Model.$routes.receipts.readText(id), {
          save: false
        })
      }
      configActions.readHtml = function (id) {
        return this.get(Model.$routes.receipts.readHtml(id), {
          save: false
        })
      }
      configActions.readPdf = function (id) {
        return this.get(Model.$routes.receipts.readPdf(id), {
          responseType: 'blob',
          save: false
        })
      }
      configActions.sendToEmail = function (payload, id, token) {
        return this.post(Model.$routes.receipts.sendToEmail(id), payload, {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.returnReceipt = function (payload) {
        return this.post(Model.$routes.receipts.returnReceipt(), payload, { save: false })
      }
      configActions.returnReceiptValidate = function (payload) {
        return this.post(Model.$routes.receipts.returnReceiptValidate(), payload, { save: false })
      }
      configActions.returnReceiptReset = function (payload) {
        return this.post(Model.$routes.receipts.returnReceiptReset(), payload, { save: false })
      }
      configActions.sendToSms = function (payload, id, token) {
        return this.post(Model.$routes.receipts.sendToSms(id), payload, {
          headers: { 'X-Processing-Authorization': token },
          save: false
        })
      }
      configActions.salesChart = function (payload, params) {
        return this.post(Model.$routes.receipts.salesChart(), payload, { params, save: false })
      }
      configActions.salesChartInfo = function (payload, params) {
        return this.post(Model.$routes.receipts.salesChartInfo(), payload, { params, save: false })
      }
      configActions.listMultiAccount = function (payload, options = {}) {
        return this.post(Model.$routes.receipts.listMultiAccount(), payload, { dataKey: 'data', ...options })
      }
      configActions.processingExport = function (payload) {
        return this.post(Model.$routes.receipts.processingExport(), payload, { save: false })
      }
      configActions.processingExportStatus = function (id) {
        return this.get(Model.$routes.receipts.processingExportStatus(id), { save: false })
      }
      configActions.processingExportGetReceipts = function (id) {
        return this.get(Model.$routes.receipts.processingExportGetReceipts(id), {
          responseType: 'blob',
          save: false
        })
      }
      configActions.processingCancelReceipt = function (id, token) {
        return this.delete(Model.$routes.receipts.processingRead(id),
          {
            save: false,
            headers: { 'X-Processing-Authorization': token }
          }
        )
      }
      configActions.logAnalytics = function (payload) {
        return this.post(Model.$routes.receipts.logAnalytics(), payload, { save: false })
      }
      return configActions
    }
  }
}

export default Receipts
