<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    :value="isOpened"
    v-on="$listeners"
    :max-width="width"
    :showCloseBtn="showCloseBtn"
    :contentWithoutPadding="contentNoGutters"
    @input="close"
  )
    template(#title v-if="title" )
      p(class='ma-0 pa-0' :class="titleClass") {{title}}
    template(#subtitle v-if="subtitle" )
      p(class='text-center ma-0 mt-2 pa-0') {{subtitle}}
    template(#content)
      v-row(:class="{ 'h-100': contentFullHeight, [contentClass]: contentClass }" :no-gutters="contentNoGutters")
        v-col(cols="12" v-if="text" :class="{ 'h-100': contentFullHeight }")
          | {{text}}
        v-col(cols="12" v-if="component" :class="{ 'h-100': contentFullHeight }")
          component(
            :is="component"
            v-bind="{...componentProps, isOpened}"
            :closeModal="onCloseEvent"
            :toggleCloseBtn="toggleCloseBtn"
            :changeDialogWidth="changeDialogWidth"
            :changeDialogGutters="changeDialogGutters"
            v-on="componentListeners"
          )
    template(#actions v-if="showActions")
      v-row(no-gutters justify="center" )
        v-col(cols="auto")
          v-btn(@click="confirm" color="primary"  class="main-button" depressed)
            | {{buttonText.approve}}
        v-col(cols="auto")
          v-btn(@click="close" class="ml-4 secondary-button color-error" outlined)
            | {{buttonText.dismiss}}
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import Dialog from '~/models/system/Dialog'

// used components
// TODO this must be rethink
import MBlockReceiptSaleDiscount from '~/modules/receipt/views/components/m-block-receipt-sale-discount'
import MBlockReceiptSaleMixedPaymentTypes
  from '~/modules/receipt/views/components/m-block-receipt-sale-mixed-payment-types'
import MBlockShiftClose from '~/components/modules/blocks/concrete/shift/m-block-shift-close'
import BlockReportZView from '~/modules/reports/views/components/block-report-z-view'
import MFormDepositsignToken from '~/components/modules/forms/concrete/m-form-depositsign-token'
import MFormBillingTaxBill from '~/components/modules/forms/concrete/billing/m-form-billing-tax-bill'
import MBlockMultipleTaxBillsSending
  from '~/components/modules/blocks/concrete/tax-bills/m-block-multiple-tax-bills-sending'
import MFormBlock from '~/components/modules/blocks/m-form-block'
import MBlockGoodsImportResult from '~/components/modules/blocks/concrete/goods/m-block-goods-import-result'
import MOrmTable from '~/components/modules/tables/orm/m-orm-table'
import FormEmployeeModify from '~/modules/employees/views/components/form-employee-modify'
import FormEmployeePermissions from '~/modules/employees/views/components/form-employee-permissions'
import FormEmployeeUploadEdsKey from '~/modules/employees/views/components/form-employee-upload-eds-key'
import MFormOutletModify from '~/components/modules/forms/concrete/m-form-outlet-modify'
import MFormPaymentSettings from '~/components/modules/forms/concrete/payment/m-form-payment-settings'
import MChildGoodsList from '~/components/modules/m-child-goods-list'
import MBlockReceiptSaleExciseStamp
  from '~/components/modules/blocks/concrete/receipt/sale/m-block-receipt-sale-excise-stamp'
import MBlockBarcodeScanner from '~/components/modules/blocks/concrete/m-block-barcode-scanner'
import MBlockGoodsScanner from '~/components/modules/blocks/concrete/goods/m-block-goods-scanner'
import MBlockSetOrganization from '~/components/modules/blocks/concrete/m-block-set-organization'
import MBlockWrongSuppliesCount from '~/modules/goods/views/components/m-block-wrong-supplies-count'
import MBlockSupportMessage from '~/components/modules/blocks/concrete/m-block-support-message'
import MGoodsImport from '~/modules/goods/views/components/m-goods-import'
import MGoodsExport from '~/modules/goods/views/components/m-goods-export'
import MBlockGoodsGroupRemoval from '~/modules/goods/views/components/m-block-goods-group-removal'
import MBlockOrmReceiptView from '~/modules/receipt/views/components/m-block-orm-receipt-view'
import BlockProcessingEmployeeLogin from '~/modules/receipt/views/components/dialogs/block-processing-employee-login'
import BlockReceiptService from '~/modules/receipt/views/components/dialogs/block-receipt-service'
import BlockReceiptTextView from '~/modules/receipt/views/components/dialogs/block-receipt-text-view'
import BlockOrderError from '~/modules/orders/views/components/errors/block-order-error'
import MBlockEttnOrderView from '~/modules/ettn/views/m-block-ettn-order-view'
import MBlockAcquiringOrderView from '~/modules/acquiring/views/m-block-acquiring-order-view'
import MBlockAcquiringOrderCreateReceipt from '~/modules/acquiring/views/m-block-acquiring-order-create-receipt'
import BlockAcquiringPaymentInfo from '~/modules/receipt/views/components/dialogs/block-acquiring-payment-info'
import MBlockSetEttnSettings from '~/components/modules/blocks/concrete/ettn/m-block-set-ettn-settings'
import MBlockSetOutletEmail from '~/components/modules/blocks/concrete/m-block-set-outlet-email'
import BlockAddNotification from '~/modules/notifications/views/components/block-add-notification'
import BlockNotificationsList from '~/modules/notifications/views/components/block-notifications-list'
import BlockSingleNotification from '~/modules/notifications/views/components/block-single-notification'
import TOrmSimpleData from '~/components/templates/orm/t-orm-simple-data'
import BlockAddWorkingOutlet from '~/modules/we-are-working/views/components/block-add-working-outlet'
import BlockCreateTaxReport from '~/modules/reports/views/components/block-create-tax-report'
import BlockTaxReportView from '~/modules/reports/views/components/block-tax-report-view'
import MBlockSwitchSuppliesSetting from '~/modules/goods/views/components/m-block-switch-supplies-setting'
import MFormOfficePayment from '~/components/modules/blocks/concrete/payment/m-form-office-payment'
import MFormSmsPayment from '~/components/modules/blocks/concrete/payment/m-form-sms-payment'
import BlockChangeEmployeeEdsKey from '~/modules/employees/views/components/block-change-employee-eds-key'
import BlockReviewForm from '~/modules/reviews/views/components/block-review-form'
import BlockPosTerminalPaymentProcess
  from '~/modules/receipt/views/components/dialogs/block-pos-terminal-payment-process'
import BlockSettingsHtmlReceiptForm from '~/modules/profile/views/components/block-settings-html-receipt-form'
import BlockSettingsTextReceiptForm from '~/modules/profile/views/components/block-settings-text-receipt-form'
import BlockTaxReportAdditionForm from '~/modules/reports/views/components/block-tax-report-addition-form'
import BlockSignDsPdf from '~/modules/employees/views/components/block-sign-ds-pdf'
import BlockTapToPhoneForm from '~/modules/acquiring/views/block-tap-to-phone-form'
import FormDepositsignPassword from '~/modules/employees/views/components/form-depositsign-password'
import BlockPosTerminalForm from '~/modules/acquiring/views/block-pos-terminal-form'
import BlockAllGoodsDeleting from '~/modules/goods/views/components/block-all-goods-deleting'
import BlockPosTerminalSettingsForm from '~/modules/acquiring/views/block-pos-terminal-settings-form'
import MFormOutletSettings from '~/components/modules/forms/concrete/m-form-outlet-settings'
import BlockABankAcquiringOrderPdf from '~/modules/acquiring/views/a-bank/block-a-bank-acquiring-order-pdf'
import BlockProductView from '~/modules/goods/views/components/block-product-view'
import BlockTapToPhoneSettingsForm from '~/modules/acquiring/views/block-tap-to-phone-settings-form'
import BlockAddRefund from '~/modules/refunds/views/components/block-add-refund'
import BlockConfirmRefundRequestForm from '~/modules/refunds/views/components/block-confirm-refund-request-form'
import BlockApplicationPdfView from '~/modules/refunds/views/components/block-application-pdf-view'
import BlockEpzDataForm from '~/modules/receipt/views/components/dialogs/block-epz-data-form'
import BlockDeliveryOrderForm from '~/modules/receipt/views/components/dialogs/block-delivery-order-form'
import BlockDeliveryOrderInfo from '~/modules/receipt/views/components/dialogs/block-delivery-order-info'
import BlockTerminalUsageInfo from '~/modules/receipt/views/components/dialogs/block-terminal-usage-info'
import BlockReceiptsExport from '~/modules/receipt/views/components/dialogs/block-receipts-export'
import MFormBillingPriceSet from '~/components/modules/forms/concrete/billing/m-form-billing-price-set'
import BlockCancelReceipt from '~/modules/receipt/views/components/dialogs/block-cancel-receipt'
import MBlockGoodsGroupEdit from '~/modules/goods/views/components/m-block-goods-group-edit'
import MBlockGoodsGroupAdd from '~/modules/goods/views/components/m-block-goods-group-add'
import MBlockImageCropper from '~/components/modules/blocks/concrete/m-block-image-cropper'
import MBlockEttnOrderPhoneError from '~/modules/ettn/views/m-block-ettn-order-phone-error'
import MBlockEttnOrderCreateReceipt from '~/modules/ettn/views/m-block-ettn-order-create-receipt'
import BlockMallReportForm from '~/modules/reports/views/components/mall-report/block-mall-report-form'
import BlockMallReportSettings from '~/modules/reports/views/components/mall-report/block-mall-report-settings'
import BlockMallReportDownload from '~/modules/reports/views/components/mall-report/block-mall-report-download'
import BlockGoodsPrintForm from '~/modules/goods/views/components/block-goods-print-form'
import BlockGoodsPriceTagsPrint from '~/modules/goods/views/components/block-goods-price-tags-print'
import BlockPrepaymentPayment from '~/modules/prepayment/views/components/block-prepayment-payment'
import BlockReceiptsChainView from '~/modules/receipt/views/components/dialogs/block-receipts-chain-view'
import BlockReceiptEmployeeKeyEnd from '~/modules/receipt/views/components/blocks/block-receipt-employee-key-end'
import BlockIbanForm from '~/modules/acquiring/views/block-iban-form'
import BlockIbanPayment from '~/modules/acquiring/views/block-iban-payment'
import BlockIbanUsageInfo from '~/modules/receipt/views/components/dialogs/block-iban-usage-info'
import BlockQrAcquiringForm from '~/modules/acquiring/views/qr-acquiring/block-qr-acquiring-form'
import BlockReceiptSaleSmsBalance from '~/modules/receipt/views/components/sale/block-receipt-sale-sms-balance'
import BlockInternetAcquiringForm from '~/modules/acquiring/views/internet-acquiring/block-internet-acquiring-form'
import BlockMonobankAcquiringSettingsForm from '~/modules/acquiring/views/monobank/block-monobank-acquiring-settings-form'
import BlockAcquiringSuccess from '~/modules/acquiring/views/block-acquiring-success'
import BlockEmployeeInformation from '~/modules/employees/views/components/block-employee-information'
import BlockEasypayAcquiringSettingsForm
  from '~/modules/acquiring/views/internet-acquiring/block-easypay-acquiring-settings-form'
import BlockMonobankAcquiringNoTerminals
  from '~/modules/acquiring/views/monobank/block-monobank-acquiring-no-terminals'
import BlockCreateReceiptOnboarding
  from '~/modules/receipt/views/components/dialogs/block-create-receipt-onboarding'
import MBlockEttnOrders from '~/components/modules/blocks/concrete/ettn/m-block-ettn-orders'
import BlockPromotionGreeting from '~/modules/promotion/views/components/block-promotion-greeting'
import BlockPromotionCashRegisters from '~/modules/promotion/views/components/block-promotion-cash-registers'
import BlockPromotionSendProgress from '~/modules/promotion/views/components/block-promotion-send-progress'
import BlockPromotionSuccess from '~/modules/promotion/views/components/block-promotion-success'
import BlockPromotionDialog from '~/modules/promotion/views/components/block-promotion-dialog'
import BlockSelectGoodsDeleting from '~/modules/goods/views/components/block-select-goods-deleting'
import BlockTipsEmployeeModal from '~/modules/receipt/views/components/blocks/block-tips-employee-modal'
import MLoadingState from '~/modules/goods/views/import-export-flow/m-loading-state.vue'
import MConfirmExitState from '~/modules/goods/views/import-export-flow/m-confirm-exit-state.vue'
import MErrorState from '~/modules/goods/views/import-export-flow/m-error-state.vue'
import MSuccessState from '~/modules/goods/views/import-export-flow/m-success-state.vue'
import BlockRozetkapayConnecting from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-connecting'
import BlockReceiptView from '~/modules/receipt/views/components/dialogs/block-receipt-view'
import BlockRozetkapayStatusExtended from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-status-extended'
import BlockSelectDeliveryDeleting from '~/modules/goods/views/components/block-select-delivery-deleting.vue'
import BlockRozetkapaySuccess from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-success.vue'
import BlockKeyVerificationWarning from '~/modules/tax-service/views/block-key-verification-warning'

export default {
  components: {
    BlockSelectDeliveryDeleting,
    MSuccessState,
    MErrorState,
    MConfirmExitState,
    BlockABankAcquiringOrderPdf,
    BlockAcquiringPaymentInfo,
    BlockAcquiringSuccess,
    BlockAddNotification,
    BlockAddRefund,
    BlockAddWorkingOutlet,
    BlockAllGoodsDeleting,
    BlockApplicationPdfView,
    BlockCancelReceipt,
    BlockChangeEmployeeEdsKey,
    BlockConfirmRefundRequestForm,
    BlockCreateReceiptOnboarding,
    BlockCreateTaxReport,
    BlockDeliveryOrderForm,
    BlockDeliveryOrderInfo,
    BlockEasypayAcquiringSettingsForm,
    BlockEmployeeInformation,
    BlockEpzDataForm,
    BlockGoodsPriceTagsPrint,
    BlockGoodsPrintForm,
    BlockIbanForm,
    BlockIbanPayment,
    BlockIbanUsageInfo,
    BlockInternetAcquiringForm,
    BlockMallReportDownload,
    BlockMallReportForm,
    BlockMallReportSettings,
    BlockMonobankAcquiringNoTerminals,
    BlockMonobankAcquiringSettingsForm,
    BlockNotificationsList,
    BlockOrderError,
    BlockPosTerminalForm,
    BlockPosTerminalPaymentProcess,
    BlockPosTerminalSettingsForm,
    BlockPrepaymentPayment,
    BlockProcessingEmployeeLogin,
    BlockProductView,
    BlockPromotionCashRegisters,
    BlockPromotionDialog,
    BlockPromotionGreeting,
    BlockPromotionSendProgress,
    BlockPromotionSuccess,
    BlockQrAcquiringForm,
    BlockReceiptEmployeeKeyEnd,
    BlockReceiptSaleSmsBalance,
    BlockReceiptService,
    BlockReceiptTextView,
    BlockReceiptView,
    BlockReceiptsChainView,
    BlockReceiptsExport,
    BlockReportZView,
    BlockReviewForm,
    BlockSelectGoodsDeleting,
    BlockSettingsHtmlReceiptForm,
    BlockSettingsTextReceiptForm,
    BlockSignDsPdf,
    BlockSingleNotification,
    BlockTapToPhoneForm,
    BlockTapToPhoneSettingsForm,
    BlockTaxReportAdditionForm,
    BlockTaxReportView,
    BlockTerminalUsageInfo,
    BlockTipsEmployeeModal,
    FormDepositsignPassword,
    FormEmployeeModify,
    FormEmployeePermissions,
    FormEmployeeUploadEdsKey,
    MBlockAcquiringOrderCreateReceipt,
    MBlockAcquiringOrderView,
    MBlockBarcodeScanner,
    MBlockEttnOrderPhoneError,
    MBlockEttnOrderCreateReceipt,
    MBlockEttnOrderView,
    MBlockEttnOrders,
    MBlockGoodsGroupAdd,
    MBlockGoodsGroupEdit,
    MBlockGoodsGroupRemoval,
    MBlockGoodsImportResult,
    MBlockGoodsScanner,
    MBlockImageCropper,
    MBlockMultipleTaxBillsSending,
    MBlockOrmReceiptView,
    MBlockReceiptSaleDiscount,
    MBlockReceiptSaleExciseStamp,
    MBlockReceiptSaleMixedPaymentTypes,
    MBlockSetEttnSettings,
    MBlockSetOrganization,
    MBlockSetOutletEmail,
    MBlockShiftClose,
    MBlockSupportMessage,
    MBlockSwitchSuppliesSetting,
    MBlockWrongSuppliesCount,
    MChildGoodsList,
    MFormBillingPriceSet,
    MFormBillingTaxBill,
    MFormBlock,
    MFormDepositsignToken,
    MFormOfficePayment,
    MFormOutletModify,
    MFormOutletSettings,
    MFormPaymentSettings,
    MFormSmsPayment,
    MGoodsExport,
    MGoodsImport,
    MLoadingState,
    MOrmTable,
    QrcodeVue,
    TDialog,
    TOrmButtons,
    TOrmSimpleData,
    BlockRozetkapayConnecting,
    BlockRozetkapayStatusExtended,
    BlockRozetkapaySuccess,
    BlockKeyVerificationWarning
  },
  props: {
    type: {
      type: String,
      default: 'content'
    }
  },
  data: () => ({
    showCloseBtn: true,
    dialogWidth: null,
    dialogNoGutters: false
  }),
  computed: {
    dialog () {
      return Dialog.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.dialog, 'isOpened', false)
    },
    params () {
      return this._.get(this.dialog, 'params', '')
    },
    title () {
      const title = this._.get(this.params, 'title', '')
      return this._.isFunction(title) ? title(this) : this.$t(title)
    },
    subtitle () {
      const subtitle = this._.get(this.params, 'subtitle', '')
      return this._.isFunction(subtitle) ? subtitle(this) : this.$t(subtitle)
    },
    text () {
      const text = this._.get(this.params, 'text', '')
      return this._.isFunction(text) ? text(this) : this.$t(text)
    },
    showActions () {
      return this._.get(this.params, 'showActions', false)
    },
    component () {
      return this._.get(this.params, 'component')
    },
    componentProps () {
      return this._.get(this.params, 'componentProps')
    },
    componentListeners () {
      return this._.get(this.params, 'componentListeners')
    },
    contentFullHeight () {
      return this._.get(this.params, 'contentFullHeight', false)
    },
    contentNoGutters () {
      return this.dialogNoGutters || this._.get(this.params, 'contentNoGutters', false)
    },
    buttonText () {
      const defaultTexts = {
        approve: this.$t('Approve'),
        dismiss: this.$t('Dismiss')
      }
      return this._.get(this.params, 'buttonText', defaultTexts)
    },
    width () {
      return this.dialogWidth || this._.get(this.params, 'width', '800px')
    },
    titleClass () {
      return this._.get(this.params, 'titleClass', 'mb-3')
    },
    contentClass () {
      return this._.get(this.params, 'contentClass', '')
    },
    onClose () {
      return this._.get(this.params, 'onClose')
    }
  },
  watch: {
    $route () {
      if (this.isOpened) {
        this.close()
      }
    },
    'params.hideCloseButton' (val) {
      this.showCloseBtn = !val
    }
  },
  created () {
    if (!this.dialog) {
      Dialog.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    changeDialogGutters (val) {
      this.dialogNoGutters = Boolean(val)
    },
    changeDialogWidth (val) {
      this.dialogWidth = val
    },
    async close () {
      this.changeDialogWidth(null)
      if (this._.isFunction(this.onClose)) {
        await this.onClose()
      }
      await this.dialog.close(false, true)
    },
    confirm () {
      this.dialog.close(true, true)
    },
    onCloseEvent (payload, confirmed = true) {
      this.dialog.close(payload, confirmed)
    },
    toggleCloseBtn (val) {
      this.showCloseBtn = val
    }
  }
}
</script>
