<template lang="pug">
  div(class="m-block-uploader")
    div(class="uploader-wrapper" :class="{ 'uploader-wrapper--error': hasError }")
      div(class="uploader")
        div(class="d-flex" :class="{ 'w-100 flex-column': isResponsive }")
          div(:class="{ 'mr-5': !isResponsive, 'mb-5': isResponsive }")
            div(class="uploader__icon" :style="previewImageStyles")
              template(v-if="isUploaderImageType")
                e-svg-icon(v-if="!previewImage") {{ icon }}
              template(v-else)
                e-svg-icon {{ icon }}
          div(class="uploader__content")
            h4(class="uploader__title")
              span {{ title }}
              e-hint-tooltip(v-if="isResponsive && tooltip" top)
                div(class="uploader__tooltip") {{ tooltip }}
            p(v-if="subtitle" class="uploader__subtitle") {{ subtitle }}
        div(class="uploader__actions" :class="{'pt-3': xsDevice}")
          e-hint-tooltip(v-if="!isResponsive && tooltip" top)
            div(class="uploader__tooltip") {{ tooltip }}
          ValidationObserver(ref="uploadValidator" slim v-slot="{ errors }" :class="{ 'w-100': isResponsive, 'ml-2': tooltip }")
            template(v-if="isUploaderImageType")
              e-button-file-upload(
                v-show="!previewImage"
                ref="fileInput"
                :files.sync="localFiles"
                :multiple="multiple"
                :accept="accept"
                :rules="rules"
                :name="validationFieldName"
                :validation-mode="validationMode"
                @setErrors="setImageErrors"
                :btn-text="btnText"
              )
              v-btn(
                v-if="previewImage"
                class="secondary-button color-error"
                :class="{ 'w-100': isResponsive }"
                @click="deleteFiles"
              ) {{ $t('Delete') }}
            template(v-else-if="isUploaderFilesType")
              e-button-file-upload(
                ref="fileInput"
                :files.sync="localFiles"
                :multiple="multiple"
                :accept="accept"
                :rules="rules"
                :name="validationFieldName"
                :validation-mode="validationMode"
                @setErrors="setImageErrors"
                :btn-text="btnText"
              )
      template(v-if="isUploaderFilesType")
        block-tap-to-phone-file-status(
          v-if="localFiles && localFiles.length"
          :files="localFiles"
          @delete="removeFile"
        )
    div(v-if="errors && errors.length" class="uploader__error") {{ errors[0] }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EButtonFileUpload from '~/components/elements/buttons/e-button-file-upload'
import BlockTapToPhoneFileStatus from '~/modules/acquiring/views/block-tap-to-phone-file-status'
import EHintTooltip from '~/components/elements/tooltips/e-hint-tooltip'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'MBlockUploader',
  components: {
    ESvgIcon,
    EButtonFileUpload,
    BlockTapToPhoneFileStatus,
    EHintTooltip
  },
  mixins: [responsive],
  props: {
    // TODO: do we really need to divide uploader by type ?
    type: {
      type: String,
      default: 'files',
      validator: (value) => {
        return ['files', 'image'].includes(value)
      }
    },
    icon: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: null
    },
    validationFieldName: {
      type: String,
      default: ''
    },
    validationMode: {
      type: String,
      default: 'aggressive'
    },
    previewImage: {
      type: String,
      default: null
    },
    hasImageBeenHidden: {
      type: Boolean,
      default: null
    },
    files: {
      type: Array,
      default: () => []
    },
    inputErrors: {
      type: Array,
      default: null
    },
    tooltip: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: 'Download'
    }
  },
  data () {
    return {
      localFiles: this._.cloneDeep(this.files),
      hasError: false,
      errors: null
    }
  },
  computed: {
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    },
    isUploaderFilesType () {
      return this.type === 'files'
    },
    isUploaderImageType () {
      return this.type === 'image'
    },
    previewImageStyles () {
      if (this.isUploaderImageType) {
        if (this.previewImage) {
          const styles = {
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }
          if (this.checkStrDataUrl(this.previewImage)) {
            styles.backgroundImage = `url(${this.previewImage})`
          } else {
            styles.backgroundImage = `url(${this.previewImage}?${Date.now()})`
          }
          return styles
        }
      }
      return null
    }
  },
  watch: {
    localFiles (val) {
      // updating files from input to parent components
      if (val.length !== this.files.length) {
        this.$emit('update:files', val)
      }
      this.hasError = false
      this.errors = null
      if (this.validationMode === 'aggressive') {
        this.$nextTick(async () => {
          this.hasError = !await this.$refs.uploadValidator.validate()
          this.errors = this.$refs?.fileInput && this.$refs.fileInput?.$refs?.validation.errors
        })
      }
    },
    files (value) {
      // deleting files from parent components
      if (!value.length) {
        this.localFiles = []
      }
    },
    inputErrors (errors) {
      this.hasError = !!(errors && errors.length)
      this.errors = errors
    }
  },
  methods: {
    removeFile (fileIndex) {
      this.localFiles.splice(fileIndex, 1)
      this.hasError = false
      this.errors = null
    },
    deleteFiles () {
      this.localFiles = []
      // TODO: emit delete photo
      this.$emit('deleteImage')
    },
    async validate () {
      return await this.$refs.uploadValidator.validate()
    },
    async reset () {
      await this.$refs.uploadValidator.reset()
    },
    checkStrDataUrl (str) {
      // A RegExp to match a Data URL pattern
      const dataUrlPattern = /^data:([\w/+]+);base64,([a-zA-Z0-9+/]+={0,2})$/
      return dataUrlPattern.test(str)
    },
    setImageErrors (errors) {
      if (errors.length) {
        this.hasError = true
        this.errors = errors
      }
    }
  }
}
</script>

<style scoped lang="scss">
.m-block-uploader {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
.uploader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  &-wrapper {
    border: 2px dashed var(--light-grey, #F0F1F2);
    border-radius: 16px;
    padding: 16px;
    overflow: hidden;

    &--error {
      border-color: #ff002b;
    }
  }

  &__content {
    width: 100%;
  }

  &__errors {
    position: absolute;
    bottom: -45px;
    left: 0;
    font-size: 10px;
    line-height: 14px;
    color: #ff002b;
  }

  &__error {
    font-size: 12px;
    line-height: 14px;
    color: #ff002b;
    margin-top: 4px;
  }

  &__icon {
    width: 48px;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #F0F1F2;
    border-radius: 12px;
  }

  &__title {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 14px;
    }
  }

  &__subtitle {
    max-width: 450px;
    font-weight: 300;
    font-size: 11px;
    line-height: 18px;
    color: #000000;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 12px;
    }
  }

  &__tooltip {
    max-width: 280px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      max-width: 380px;
    }
  }

  &__image-preview {
    max-width: fit-content;
    height: fit-content;
  }

  &__actions {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    @media (min-width: map-get($breakpoints, 'xs')) {
      width: auto;
    }
  }
}
</style>
