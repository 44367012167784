<template lang="pug">
  div(class="dialog")
    div(
      v-if="viewLoading"
      class="dialog__loader"
    )
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="5"
      )
    template(v-else)
      div(class="dialog__content")
        iframe(
          ref="receiptDoc"
          class="d-none"
        )
        div(:class="{ 'dialog__frame': true, 'dialog__frame--html': viewDataType === 'html' }")
          div(
            v-dompurify-html="localView"
            v-if="viewDataType === 'html' && !notFound"
          )
          pre(v-if="viewDataType === 'text' && !notFound") {{ localView }}
          div(
            v-if="notFound"
            class="dialog__text"
          )  {{ $t('Requested preview not exist. Please contact with manager.') }}
      div(class="dialog__actions")
        v-row(v-if="localView")
          v-col(cols="12")
            div(class="receipt__buttons")
              e-link(@click="print" :loading="printLoading")
                e-svg-icon(class="mr-2") printer-3
                | {{ $t('Print') }}
              e-link(v-if="showActionButtons && canSendSms" @click="sendToSms")
                e-svg-icon(class="mr-2") receipt-sms
                | {{ $t('Send to sms') }}
              e-link(v-if="showActionButtons && isReceipt" @click="sendToEmail")
                e-svg-icon(class="mr-2") receipt-email
                | {{ $t('Sent to email') }}
              e-link(v-if="isReceipt" @click="copyToClipboard")
                e-svg-icon(class="mr-2") receipt-link
                | {{ $t('Copy link') }}
              e-link(v-if="receiptId" @click="download" :loading="downloadLoading")
                e-svg-icon(class="mr-2") receipt-download
                | {{ $t('Download') }}
              e-link(v-if="showActionButtons && isSellOrReturnReceipt" @click="openReceiptInDps")
                e-svg-icon(class="mr-2" size="mdl") read-2
                | {{ $t('View in DPS') }}
              e-link(
                v-if="localPosTerminalSettings"
                @click="posTerminalBtnClick('print')"
                :loading="receiptDataLoadings['print'] || printOnPosTerminalLoading"
              )
                e-svg-icon(class="mr-2") printer-3
                | {{$t('Print on the Android terminal')}}
              e-link(
                v-if="localPosTerminalSettings"
                @click="posTerminalBtnClick('display')"
                :loading="receiptDataLoadings['display'] || displayOnPosTerminalLoading"
              )
                e-svg-icon(class="mr-2") terminal-2
                | {{$t('Display on the Android terminal')}}
</template>

<script>
import Download from 'js-file-download'
import EProgressCircular from '~/components/elements/progress/e-progress-circular.vue'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import converters from '~/mixins/methods/converters'
import clipboard from '~/mixins/global/_clipboard'
import ELink from '~/components/elements/links/e-link'
import Employees from '~/modules/employees/models/Employees'
import CashRegisters from '~/models/directories/CashRegisters'
import Receipts from '~/modules/receipt/models/Receipts'
import AppNotifications from '~/services/Notifications/AppNotifications'
import SmsService from '~/services/Receipt/SmsService'
import contentDialog from '~/mixins/dialogs/contentDialog'
import { formats } from '~/const/global'
import processingApiRequest from '~/modules/receipt/mixins/actions/processingApiRequest'
import svoeIT from '~/modules/receipt/mixins/svoeIT'
import posTerminalProcesses from '~/modules/receipt/mixins/posTerminalProcesses'

const extensionsMap = {
  html: 'html',
  pdf: 'pdf',
  text: 'txt'
}
export default {
  components: {
    EProgressCircular,
    ESvgIcon,
    ELink
  },
  mixins: [converters, clipboard, contentDialog, processingApiRequest, svoeIT, posTerminalProcesses],
  props: {
    model: {
      type: Function,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({}),
      required: true
    },
    view: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    downloadLoading: false,
    printLoading: false,
    viewLoading: false,
    localView: null,
    downloaded: null,
    notFound: false,
    imgUrl: '',
    localPosTerminalSettings: null,
    receiptDataLoadings: {
      print: false,
      display: false
    }
  }),
  computed: {
    type () {
      return this._.get(this.config, 'type', 'receipt')
    },
    posTerminalSettings () {
      return this._.get(this.config, 'posTerminalSettings')
    },
    showActionButtons () {
      return this._.get(this.config, 'showActionButtons', true)
    },
    ormModel () {
      return this._.get(this.config, 'model', null) || this.model
    },
    isReceipt () {
      return this.type === 'receipt'
    },
    isSellOrReturnReceipt () {
      const receiptType = this._.get(this.item, 'type')
      return this.isReceipt && (receiptType === Receipts.TYPES.SELL || receiptType === Receipts.TYPES.RETURN || receiptType === Receipts.TYPES.CURRENCY_EXCHANGE)
    },
    disableQr () {
      return !!this.config?.disableQr
    },
    width () {
      return this.config.width || '470px'
    },
    downloadedFileName () {
      const name = this.config.downloadNameField
        ? this._.get(this.item, this.config?.downloadNameField) + `_${this.config?.downloadPrefix}`
        : Date.now() + `_${this.config?.downloadPrefix}`
      const ext = extensionsMap[this.downloadDataType]
      return name + '.' + ext
    },
    viewDataType () {
      return this.config?.view
    },
    downloadDataType () {
      return this.config?.download
    },
    downloadAction () {
      return this.ormModel.api()['processingRead' + this._.upperFirst(this.downloadDataType)]
    },
    viewAction () {
      return this.ormModel.api()['processingRead' + this._.upperFirst(this.viewDataType)]
    },
    receiptId () {
      const requestField = this._.get(this.config, 'requestField', null)
      return requestField ? this.item[requestField] : this.item?.id
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    },
    ormButtons () {
      return [
        {
          text: 'Download',
          attrs: {
            color: 'primary',
            text: true,
            depressed: true,
            class: ['mr-2']
          },
          call: this.download
        }
      ]
    },
    canSendSms () {
      return Boolean(this.isReceipt && this._.get(this.$Organization, 'enableSmsModule'))
    }
  },
  mounted () {
    this.fill()
  },
  methods: {
    async posTerminalBtnClick (type) {
      try {
        this.receiptDataLoadings[type] = true
        const receiptHtml = this._.get(await Receipts.api().processingReadText(this.receiptId), 'response.data')
        const qrCodeUrl = this._.get(await Receipts.api().processingRead(this.receiptId, this._.get(this.authenticatedCashier, 'accessToken', null)), 'response.data.tax_url', null)
        const params = {
          posTerminalSettings: this.localPosTerminalSettings,
          payload: {
            receipt: receiptHtml,
            qr_url: qrCodeUrl
          }
        }
        if (type === 'display') {
          await this.displayOnPosTerminal(params)
        } else if (type === 'print') {
          await this.printOnPosTerminal(params)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.receiptDataLoadings[type] = false
      }
    },
    genHexString (len) {
      const hex = '0123456789abcdef'
      let output = ''
      for (let i = 0; i < len; ++i) {
        output += hex.charAt(Math.floor(Math.random() * hex.length))
      }
      return output
    },
    openReceiptInDps () {
      const id = this._.get(this.item, 'fiscalCode')
      const sum = Math.round(this._.get(this.item, 'totalSum', 0) - this._.get(this.item, 'roundSum', 0))
      const sm = (sum / 100).toFixed(2)
      const fn = this._.get(this.item, 'cashRegister.fiscalNumber')
      const mac = this.genHexString(43)

      const fiscalDate = this._.get(this.item, 'fiscalDate', '')
      const fiscalDateArr = fiscalDate.split('T')
      let date = this._.get(fiscalDateArr, '[0]', '').replace(/-/g, '')
      const timeArr = this._.get(fiscalDateArr, '[1]', '').split('+')
      const offset = parseInt(this._.get(timeArr, '[1]', ''))
      let time = this._.get(timeArr, '[0]', '').split(':')
      let timeWithOffset = parseInt(time[0]) + offset

      if (timeWithOffset > 23) {
        timeWithOffset -= 24
        const newDate = new Date(this._.get(fiscalDateArr, '[0]', ''))
        newDate.setDate(newDate.getDate() + 1)
        date = this.$moment(newDate).format(formats.dateISO8601).replace(/-/g, '')
      }

      time[0] = timeWithOffset
      time = encodeURIComponent(time.join(':'))
      const queryParams = `id=${id}&date=${date}&time=${time}&fn=${fn}&sm=${sm}&mac=${mac}`
      const link = `https://cabinet.tax.gov.ua/cashregs/check?${queryParams}`
      window.open(link, '_blank')
    },
    downloadRequest (itemId) {
      return this.downloadAction(itemId)
    },
    viewRequest (itemId) {
      return this.viewAction(itemId)
    },
    async download () {
      try {
        this.downloadLoading = true
        const data = this._.get(await this.downloadRequest(this.receiptId), 'response.data', '')
        Download(data, this.downloadedFileName)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.downloadLoading = false
      }
    },
    async print () {
      if (this.isReceipt) {
        if (this.checkHtmlPrintOrg()) {
          await this.printHtml(this.receiptId)
        } else {
          await this.printImg()
        }
      } else {
        await this.printText()
      }
    },
    async printText () {
      try {
        this.printLoading = true
        const res = this._.get(await this.ormModel.api().processingReadText(this.receiptId), 'response.data', '')
        const cw = this.$refs.receiptDoc.contentWindow

        cw.document.open()
        cw.document.write('<pre>')
        cw.document.write(res)
        cw.document.write('</pre>')

        if (!this.disableQr) {
          const qrCode = this._.get(await this.ormModel.api().processingReadQrCode(this.receiptId), 'response.data', '')
          const qrCodeUrl = await this.blobToBase64(qrCode)
          const wrapperWidth = this._.get(this.$Organization, 'receiptSetting.width', 32) * 8
          cw.document.write('<div style="width:' + wrapperWidth + 'px; text-align: center; margin: 0; padding: 0;">')
          cw.document.write(`<img src="${qrCodeUrl}" style="width: 100%; max-width: 250px; display: block; margin: 0 auto;">`)
          cw.document.write('</div>')
        }

        cw.document.close()
        setTimeout(() => {
          cw.print()
        })
      } catch (e) {
        let err = e
        if (this._.get(e, 'response.status') === 404) {
          err = this.$t('No text representation for check printing, please try again later or contact technical support')
        }
        this.$handlers.error(err, this)
      } finally {
        this.printLoading = false
      }
    },
    async printImg () {
      try {
        this.printLoading = true
        const res = this._.get(await this.ormModel.api().processingReadPng(this.receiptId), 'response.data', '')
        const receiptUrl = await this.blobToBase64(res)
        const cw = this.$refs.receiptDoc.contentWindow
        const paperWidth = this._.get(this.$Organization, 'receiptSetting.paperWidth') || 58
        const imgWidth = (paperWidth * 3.78).toFixed(2) + 'px'
        cw.document.open()
        cw.document.write(`<img src="${receiptUrl}" alt="Receipt" style="width: ${imgWidth};">`)
        cw.document.close()
        setTimeout(() => {
          cw.print()
        })
      } catch (e) {
        let err = e
        if (this._.get(e, 'response.status') === 404) {
          err = this.$t('No check view was found, please try again later or contact technical support')
        }
        this.$handlers.error(err, this)
      } finally {
        this.printLoading = false
      }
    },
    clear () {
      this.localView = ''
      this.notFound = false
      this.viewLoading = false
      this.downloadLoading = false
    },
    async fill () {
      if (this.view) {
        this.localView = this.view
        this.localPosTerminalSettings = this.posTerminalSettings
      } else {
        try {
          this.viewLoading = true
          this.localView = this.view || this._.get(await this.viewRequest(this.receiptId), 'response.data', '')
          this.localPosTerminalSettings = this.posTerminalSettings || await this.getPosTerminalSettings()
        } catch (e) {
          if (e.response && e.response.status === 404) {
            this.notFound = true
          } else {
            this.$handlers.error(e, this)
          }
        } finally {
          this.viewLoading = false
        }
      }
    },
    copyToClipboard () {
      try {
        const id = this.receiptId
        this.copy('https://check.checkbox.ua/' + id)
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    sendToEmail () {
      this.contentDialog.open({
        title: 'Sent to email',
        width: '500px',
        component: 'm-form-block',
        componentProps: {
          buttonText: 'Send ',
          fields: [
            {
              model: 'email',
              component: 'v-text-field',
              provider: {
                vid: 'email',
                name: 'E-mail',
                rules: 'required|email'
              },
              attrs: {
                outlined: true,
                type: 'email',
                placeholder: 'E-mail'
              },
              cast: val => val && val.toLowerCase()
            }
          ],
          onSubmit: async (data) => {
            try {
              await Promise.all([
                Employees.api().filter({ mode: Employees.itemModes.checkbox.mode, limit: 10 }).all(),
                CashRegisters.api().filter({ mode: CashRegisters.itemModes.checkbox.mode, limit: 1 }).all()
              ])
              const employee = Employees.query().where((item) => {
                if (item.certificateEnd) {
                  return item.mode === Employees.itemModes.checkbox.mode && new Date().getTime() < new Date(item.certificateEnd).getTime()
                }
              }).first()
              const accessToken = this._.get(await Employees.api().processingSignin(employee), 'response.data.access_token')
              await this.processingApiRequest({
                request: token => Receipts.api().sendToEmail([data.email], this.receiptId, token),
                token: accessToken,
                employee
              })
              AppNotifications.success('The check was successfully sent')
              await this.contentDialog.close()
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        }
      })
    },
    async sendToSms () {
      await SmsService.openSmsModal(this.receiptId)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    width: 100%;
  }

  &__content {
    overflow-y: auto;
    flex-grow: 1;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  &__frame {
    min-height: 200px;

    &--html {
      max-width: 290px;

      @media (min-width: map-get($breakpoints, 'sm')) {
        min-width: 370px;
      }
    }
  }

  &__text {
    text-align: center;
    margin-top: 50px;
  }

  .e-link {
    font-size: 12px !important;
  }

  .v-card {
    padding-top: 30px;

    .v-card {
      &__actions {
        padding: 0;
      }
    }
  }
}
</style>
