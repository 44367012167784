<template lang="pug">
  div(class="dialog")
    div(class="dialog__left")
      h3(class="dialog__title py-0 pb-2") {{ $t('Order progress') }}
      //h4(class="dialog__subtitle") {{ $t('Order created') }}
      div(
        v-if="xsDevice"
        class="my-6"
      )
        block-rozetkapay-hint(
          :currentStep="step"
          :steps="steps"
          mobile
        )
      v-stepper(
        class="dialog__stepper"
        vertical
      )
        v-stepper-step(
          v-for="item in stepperSteps"
          :key="item.key"
          color="#43CCB4"
          step=""
          :complete="checkComplete(item)"
          :editable="checkEditable(item)"
          edit-icon="mdi-dots-horizontal"
        ) {{ $t(item.value) }}
      div(class="dialog__actions")
        v-btn(
          class="main-button"
          @click="closeModal"
        ) {{ $t('Clear') }}
    div(v-if="!xsDevice" class="dialog__right")
      block-rozetkapay-hint(
        :currentStep="step"
        :steps="steps"
      )
</template>

<script>
import responsive from '~/mixins/pages/responsive'
import BlockRozetkapayHint from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-hint'
import rozetkaPay from '~/modules/acquiring/mixins/rozetkaPay'

export default {
  name: 'BlockRozetkapayStatusExtended',
  components: {
    BlockRozetkapayHint
  },
  mixins: [responsive, rozetkaPay],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    step: 'status'
  }),
  computed: {
    stepperSteps () {
      const arr = this._.map(this.statusText, (value, key) => {
        if (key !== this.model.STATUSES_EXTERNAL.declined && key !== this.model.STATUSES_EXTERNAL.failed) {
          return {
            key,
            value
          }
        }
      })
      return this._.uniqBy(this._.filter(arr, Boolean), 'value')
    }
  },
  methods: {
    findIndexes (item) {
      const value = this.statusText[this.item?.orderStatusExternal]
      const currentStepIndex = this.stepperSteps.findIndex(step => step.value === value)
      const itemIndex = this.stepperSteps.findIndex(step => step.value === item.value)
      return { currentStepIndex, itemIndex }
    },
    checkComplete (item) {
      const { currentStepIndex, itemIndex } = this.findIndexes(item)
      return currentStepIndex === this.stepperSteps.length - 1 || itemIndex <= currentStepIndex
    },
    checkEditable (item) {
      const { currentStepIndex, itemIndex } = this.findIndexes(item)
      return currentStepIndex !== this.stepperSteps.length - 1 && itemIndex === currentStepIndex
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  position: relative;
  background: #fff;
  display: flex;
  height: 100%;

  &__subtitle {
    font-size: 13px;
    color: rgba(22, 27, 37, 0.75);
    font-weight: 300;
  }

  &__actions {
    padding-top: 32px;
    padding-bottom: 0;
    justify-content: flex-end;
    display: flex;
  }

  &__stepper {
    padding: 24px 0 0;
    box-shadow: none;
    width: 100%;

    &::v-deep {
      .v-stepper {
        &__step {
          padding: 0 0 16px;
          position: relative;
          background: none;
          cursor: default;
          pointer-events: none;

          &:hover {
            background: none;
          }

          &:not(.v-stepper__step--complete) {
            .v-stepper__step__step {
              background: none !important;
              border-radius: 50%;
              border: 1px solid #43CCB4;
            }
          }

          &:last-child {
            padding-bottom: 0;
          }

          &:not(:last-child) {
            &::before {
              content: '';
              display: block;
              width: 1px;
              height: calc(100% - 24px);
              background: #43CCB4;
              left: 12px;
              bottom: 0;
              position: absolute;
            }
          }

          .v-ripple__container {
            display: none;
          }
        }

        &__label {
          font-size: 14px;
          font-weight: 700;
          color: $btn-secondary-color;
          pointer-events: none;
        }
      }
    }
  }
}
</style>
