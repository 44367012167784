import { find } from 'lodash'
import taxReports from '~/modules/reports/mixins/taxReports'
import User from '~/models/administration/User'

const routePrefix = '/dashboard/reports'
const mallReportsAllowedOrganizations = [
  '7b1751eb-5278-451e-93ca-46fed33a9388', // Kulyk stage
  'b3c08cf9-3342-4184-abd8-81d606222199', // Intertop
  'fecd52eb-ad8d-4775-bb72-f4b76e225bcc', // Amadeo
  '2cfaefda-01d0-4523-a652-f698f8843d26', // Kalpa
  '34aeee95-af40-4c0a-8ab7-72b873f53b1b', // Rohovenko
  '6263c23b-0987-4ac1-a21e-b32a9abe391b', // Omelchuk Svetlana
  '16cc9f5e-f524-4288-a38a-e9f284e1785b', // Protoria
  'e6da5919-d0a2-49b1-8937-6a9af140b5c3', // Zublenko
  '8ec17d5b-c47f-4bf2-a319-761ee68fa7c9',
  'c875ddf7-8fed-4644-9b9a-7950229be863',
  'f05cda30-e5ae-4d6a-8f30-7e8227fbc0c4',
  'f868016d-ed7f-4ba6-807e-bad64a8617b6',
  '4e6b4d62-9b75-474a-9cd5-ab27cc55bc17',
  'b2644b41-1847-4dfd-8674-badb0fd641cd',
  '7bb3cb95-d375-4999-a006-2120eb57b29e',
  'a6aac099-b7b6-42ee-9ff8-4bc8cf3c415c',
  'fa8ebd1b-fdfe-4a2a-9845-93a6384dd116',
  '9dd6f984-c4e9-4192-a9dc-11dba42fc9bc',
  '991644f5-2474-46e0-bb18-8d7a47c8086e',
  '0b6f097c-d560-4f6b-9bbd-bba3f02b283f',
  'bc4ea3c2-880e-45c3-917c-fd2b29aa306e',
  '8a78ffa1-cd02-4148-b26e-505d502e669e',
  'be0a1338-80ac-4c67-9dde-d4d54bf0a4bb',
  '28bcbd7d-41c1-4027-8e93-93584c38c350',
  '145dbb2b-963e-4b36-9c36-51691eb2289f',
  '19400225-da04-403d-af27-ed8a12d8bb9f',
  '32fde4b0-5476-4176-866d-5116f762f766',
  '54fcd678-c0ec-4086-beb4-a7d848eb5059',
  '51c3c672-7921-4854-aaa3-60e6d2ef28ce',
  'a57d0e5c-f8bd-4377-b5d8-cb89f829e82a',
  '2e6714e9-0f94-47c5-8708-1356a42b3160',
  '475de216-553c-4731-af38-9b78fc80df30',
  '2530ff88-3d43-43b2-a937-411e805016b0',
  'bfd15ee4-85a4-40d4-8bed-cef22170081f',
  '3739bc04-0be7-469b-b5ee-c79ddb234c59',
  '5e624fc1-835b-46ef-a334-5820695c554a',
  'b8a83178-c116-465a-bf7d-5a3338551a17',
  '2cfaefda-01d0-4523-a652-f698f8843d26',
  'a821c0d9-2066-4a5b-9b9d-fb06d3e61a86',
  '6ecda697-6755-4c5a-9191-f2a8e823715e',
  'f692b5af-d6ff-43e0-b1b3-ad113c425033',
  'a2068615-fd2b-48c1-a7ee-8dd03a289666',
  '3bf2e4de-25b6-45cd-99b3-11ed779bc8f2',
  'b8d691c1-35ee-4ea9-8a97-1234e6fa4c6a',
  '71c33624-d125-4b85-8b03-8d59ee2e6229',
  'f7181a0e-7915-40ee-b73b-925c913a8bf8',
  'ba9c73a4-6ae9-488a-aa9e-3ee8e0cced77',
  '55c09758-2c1e-4b82-8d2a-e679fd8fd15e',
  'b1b38012-7e65-48be-b0fb-d2c98a5bd0ce',
  '24a14d03-166c-42e3-bd3c-3b5b610058dd',
  '479caa47-3bc1-432b-870f-b82b7bc633b1',
  'b4397011-9125-4446-8fa9-93a5499b249b',
  'ecaa9619-6c62-4e36-adb4-04004e6df480',
  'c18fa762-1b02-401e-a19e-73ceb5272b06',
  'ee7bae70-a3a3-4509-8cd2-19c677238f66',
  '5f87e48c-b17f-41e6-a08f-2c5749e5fe7a',
  'fce334ff-ea76-455b-9855-c3984ee211ad',
  '7101b628-05b2-4eb9-ba41-a3d9f6c3d6b5',
  '15bae729-55d0-4702-821c-93d96fdc166d',
  '73c7ca27-2186-4815-95bb-8b0096da3265',
  '8babfe68-d0b7-4401-ade5-b3cba8aeefc0',
  '1aa80f30-3e11-45ea-b5ed-d3a5a1884815',
  '6f66dc77-52df-436b-9a82-c35f7c8118b3',
  '1def11a5-ca6f-4ee3-a511-55ee0371f7a0',
  '0d519ccd-e438-4408-8a3c-9f7918707d53',
  '769a2ec9-fd47-48f5-81a4-dafecd99ea04',
  'ce220de1-9d65-45e4-b4f4-c08ecebe2672',
  'ff66c101-785a-403b-8d98-82a777312d41',
  'dc353240-daf7-4b2e-92bf-3bf6d0834c0a',
  'e0459a3e-a578-4d96-a6c5-5194fb0835f2',
  '0d1d0a0e-e331-4a12-b850-5a0a886af6df',
  'b4b62ae6-38d1-40b8-b22a-c11fb1e7b5b4',
  'c8eaead6-f534-43f7-a50a-115a6cbe9afe',
  '7cea3352-d715-4de9-8375-fa5142b58a1d',
  '3b166573-9cfd-4ff6-91ff-9da705843023',
  '1629539e-bfea-411e-a621-9427627d09dc',
  'ff66c101-785a-403b-8d98-82a777312d41',
  '332307bf-9829-489e-8d34-8bc6a3c7a784',
  '63013e9f-5487-4ad9-8bab-a73fd10aceff',
  'b1be81fc-4c4b-476d-a143-28a6509f966a',
  '28364435-f5ee-4aad-be77-72f3a3186496',
  '133ec0da-7e29-430e-9bc0-a916648f355b',
  '16cc9f5e-f524-4288-a38a-e9f284e1785b',
  '24776aab-fc61-49f0-ab8d-92a98864f8bd',
  '055f1af1-8339-44a6-bd30-344996badb27',
  '1629539e-bfea-411e-a621-9427627d09dc',
  '882d4308-77e2-4579-8e99-77bbd14af3ff',
  '9e07586e-3a0f-4c47-9808-8ae603ced836'
]

export const reports = {
  periodicReport: {
    title: 'Periodic report',
    type: 'periodicReport',
    icon: 'calendar',
    route: `${routePrefix}/periodicreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  receiptsReport: {
    title: 'Receipts report',
    type: 'receiptsReport',
    icon: 'receipt-report',
    route: `${routePrefix}/receiptsreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  extendedReports: {
    title: 'Extended reports',
    type: 'extendedReports',
    icon: 'presentation-chart',
    route: `${routePrefix}/extendedreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  zReport: {
    title: 'Reports z-report',
    type: 'zReport',
    icon: 'z-report',
    route: `${routePrefix}/zreport`,
    visible: () => true
  },
  technicalReturn: {
    title: 'Technical return report',
    type: 'technicalReturn',
    icon: 'selling-2',
    route: `${routePrefix}/technicalreturnreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  goods: {
    title: 'Goods report',
    type: 'goods',
    icon: 'shopping-cart-3',
    route: `${routePrefix}/goodsreport`,
    visible: () => true
  },
  excises: {
    title: 'Excises report',
    type: 'excises',
    icon: 'tax-report',
    route: `${routePrefix}/excisesreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  financial: {
    title: 'Financial report',
    type: 'financial',
    icon: 'finances-report',
    route: `${routePrefix}/financialreport`,
    visible: (user) => {
      const cashierRoles = Boolean(find(user.roles, role => role === User.ROLES.headCashier || role === User.ROLES.emergencyCashier || role === User.ROLES.employee))
      return !user?.globalAllOrganizationsSelected && !cashierRoles
    }
  },
  shifts: {
    title: 'Shifts report',
    type: 'shifts',
    icon: 'change-2',
    iconSize: 'bg',
    route: `${routePrefix}/shiftsreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  mall: {
    title: 'Report for mall',
    type: 'mall',
    icon: 'finances-report',
    route: `${routePrefix}/mallreport`,
    visible: (user, org) => !user?.globalAllOrganizationsSelected && mallReportsAllowedOrganizations.includes(org?.id)
  },
  tax: {
    title: 'Tax reports',
    type: 'tax',
    icon: 'taxes-report',
    route: `${routePrefix}/taxreports`,
    visible: (user, org) => {
      return !user?.globalAllOrganizationsSelected && taxReports.methods.taxReportVisible(org)
    }
  },
  dailyCashFlow: {
    title: 'Report on all FOP',
    type: 'dailyCashFlow',
    icon: 'taxes-report',
    route: `${routePrefix}/dailycashflowreport`,
    visible: (user, org) => {
      const tagId = 'd0124947-b18c-43b7-97f0-8166f0fe295b' // Pizza Day
      return !user?.globalAllOrganizationsSelected && org.tags.some(el => el.id === tagId)
    }
  }
}
