<template lang="pug">
  div(class="rozetkapay-user-form")
    ValidationObserver(ref="form" slim)
      div
        p(class="rozetkapay-user-form__subtitle mt-1") {{ $t('Company data') }}
        div(class="rozetkapay-user-form__block mb-1")
          e-input-wrapper(
            v-model="form.company_details.number_of_employees"
            :item="fields.numberOfEmployees"
          )
          e-input-wrapper(
            v-model="form.company_details.report_email"
            :item="fields.reportEmail"
          )
        div(:class="{ 'mb-7': hasLicense }")
          v-radio-group(
            v-model="hasLicense"
            v-bind="fields.hasLicense"
          )
            v-radio(:label="$t('Yes')" :value="true" :ripple="false")
            v-radio(:label="$t('No')" :value="false" :ripple="false")
          e-input-wrapper(
            v-if="hasLicense"
            v-model="form.company_details.license_numbers"
            :item="fields.licenseNumbers"
          )
      div
        p(class="rozetkapay-user-form__subtitle mt-1") {{ $t('Financial data') }}
        v-radio-group(
          v-model="form.financial_data.new_merchant"
          v-bind="fields.newMerchant"
        )
          template(v-slot:label)
            div(class="d-flex align-center")
              span {{ fields.newMerchant.label }}
              e-hint-tooltip(classes="ml-2" icon="exclamation-2" top)
                span {{ $t('"Newly established company" means that the company is registered in the current year and has not yet submitted its annual financial statements') }}
          v-radio(:label="$t('Yes')" :value="true" :ripple="false")
          v-radio(:label="$t('No')" :value="false" :ripple="false")
        e-input-wrapper(
          v-model="form.financial_data.iban"
          :item="fields.iban"
        )
        v-radio-group(
          v-model="form.financial_data.planed_financial_turnover"
          v-bind="fields.planedFinancialTurnover"
        )
          v-radio(:label="$t('up to UAH 400000')" value="400.000" :ripple="false")
          v-radio(:label="$t('more than UAH 400000')" value="> 400.000" :ripple="false")
        e-input-wrapper(
          v-model="form.financial_data.future_financial_status"
          :item="fields.futureFinancialStatus"
        )
</template>

<script>
import EInputMask from '~/components/elements/inputs/e-input-mask.vue'
import responsive from '~/mixins/pages/responsive'
import EInputWrapper from '~/components/elements/inputs/e-input-wrapper'
import EHintTooltip from '~/components/elements/tooltips/e-hint-tooltip'

export default {
  name: 'BlockRozetkapayUserForm',
  components: {
    EInputMask,
    EInputWrapper,
    EHintTooltip
  },
  mixins: [responsive],
  props: {
    order: {
      type: Object,
      default: () => ({}),
      required: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    hasLicense: false,
    form: {
      contact_details: {
        first_name: null,
        last_name: null,
        middle_name: null,
        phone_number: null,
        email: null
      },
      company_details: {
        ownership_form: 'private',
        number_of_employees: null,
        report_email: null,
        license_numbers: null,
        parent_company: null
      },
      financial_data: {
        new_merchant: null,
        iban: null,
        planed_financial_turnover: null,
        future_financial_status: null
      }
    }
  }),
  computed: {
    fields () {
      const commonRadioGroupAttrs = {
        row: true,
        hideDetails: true,
        class: 'rozetkapay-user-form__radio-group mb-7'
      }
      return {
        numberOfEmployees: {
          ...this.fieldsCommon({
            name: this.$t('Number of employees'),
            vid: 'numberOfEmployees',
            attrs: {
              items: ['0', '1-10', '11-50', '51-100', '101-500']
            }
          }),
          component: 'v-select',
          fieldVal: () => this._.get(this.data, 'company_details.number_of_employees')
        },
        reportEmail: {
          ...this.fieldsCommon({
            name: this.$t('Email for receipt of documents'),
            vid: 'reportEmail',
            provider: {
              rules: 'required|email'
            }
          }),
          fieldVal: () => this._.get(this.data, 'company_details.report_email')
        },
        iban: {
          ...this.fieldsCommon({
            name: this.$t('IBAN of your account'),
            vid: 'iban',
            provider: {
              rules: 'required|IBAN'
            }
          }),
          fieldVal: () => this._.get(this.data, 'financial_data.iban')
        },
        futureFinancialStatus: {
          ...this.fieldsCommon({
            name: this.$t('The amount of annual income from the report'),
            vid: 'futureFinancialStatus',
            provider: {
              rules: this.form.financial_data.new_merchant ? 'integer_or_decimal' : 'required|integer_or_decimal'
            }
          }),
          fieldVal: () => this._.get(this.data, 'financial_data.future_financial_status')
        },
        licenseNumbers: {
          ...this.fieldsCommon({
            name: this.$t('License number'),
            vid: 'licenseNumbers'
          }),
          hint: this.$t('You can specify multiple licenses using the "," sign'),
          fieldVal: () => this._.get(this.data, 'company_details.license_numbers')
        },
        hasLicense: {
          ...commonRadioGroupAttrs,
          label: this.$t('I have a license')
        },
        newMerchant: {
          ...commonRadioGroupAttrs,
          label: this.$t('My company is a newly established individual entrepreneur')
        },
        planedFinancialTurnover: {
          ...commonRadioGroupAttrs,
          label: this.$t('Planned monthly turnover on selected platforms')
        }
      }
    }
  },
  watch: {
    'form.financial_data.planed_financial_turnover' (val) {
      const maxStep = val === '400.000' ? 2 : 3
      this.$emit('change:maxStep', maxStep)
    }
  },
  created () {
    this.fillData()
  },
  methods: {
    fieldsCommon ({ name, vid, provider = {}, attrs = {} }) {
      return {
        component: 'v-text-field',
        classes: ['app-outlined-input'],
        attrs: {
          label: name,
          outlined: true,
          ...attrs
        },
        provider: {
          name,
          vid,
          mode: 'passive',
          rules: 'required',
          ...provider
        }
      }
    },
    fillData () {
      const newMerchant = this._.get(this.data, 'financial_data.new_merchant')
      const planedFinancialTurnover = this._.get(this.data, 'financial_data.planed_financial_turnover')
      this.form.financial_data.new_merchant = newMerchant === null ? false : newMerchant
      this.form.financial_data.planed_financial_turnover = planedFinancialTurnover || '400.000'
      this.form.contact_details.email = this._.get(this.data, 'contact_details.email', null)
      this.form.contact_details.phone_number = this._.get(this.data, 'contact_details.phone_number', null)
      this.hasLicense = Boolean(this._.get(this.data, 'company_details.license_numbers.length'))
    }
  }
}
</script>

<style scoped lang="scss">
.rozetkapay-user-form {
  &__subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    color: $btn-secondary-color;
    margin-bottom: 16px;
  }

  &__block {
    display: flex;
    gap: 24px;

    & > span {
      width: 100%;
    }
  }

  &__radio-group {
    margin: 0;

    &::v-deep {
      .v-input--radio-group__input {
        flex-wrap: wrap;

        & > .v-label {
          width: 100%;
          font-weight: 300;
          margin-bottom: 14px;
          color: $btn-secondary-color;
          height: auto;
          padding: 0;
        }
      }
    }
  }
}
</style>
