<template lang="pug">
  div(class="change-employee-eds-key")
    div(class="change-employee-eds-key__left")
      div(
        v-if="currentStep !== steps.keyReplacementOptions"
        class="change-employee-eds-key__stepper"
      ) {{ $t('Step') }} {{ currentStepNumber }} / {{ totalStepsNumber }}
      h3(
        v-if="currentStep !== steps.sign"
        class="change-employee-eds-key__title"
      )
        v-btn(
          v-if="choice"
          class="change-employee-eds-key__back-arrow"
          @click="handleStepBack"
          icon
          small
        )
          e-svg-icon(size="sm") arrow-next
        span {{ $t(title) }}
      div(
        v-if="showHintBlock && xsDevice && currentStep !== steps.sign"
        class="mb-6"
      )
        block-employee-form-hint(
          :currentStep="currentStep"
          mobile
        )
      block-employee-creating-options(
        v-if="!choice"
        @select="handleOptionSelect"
        :show-manually="false"
        :check-ds-request-allow="false"
      )
      eds-iit-checkbox(
        v-else-if="currentStep === steps.sign"
        @stepChange="handleSignPluginStepChange"
        :on-confirm="handleSignKeyConfirmed"
        :on-interface-action-error="handleSignActionError"
        :key="edsIitCheckboxKey"
        read-diia-key
        show-cached-data-message
        keep-back-btn-visible
      )
        template(v-slot:subtitle)
          div(
            v-if="xsDevice && currentSignStep !== 'keyInfo'"
            class="mb-4"
          )
            block-eds-iit-checkbox-hint(
              :current-step="currentSignStep"
              mobile
            )
      form-employee-upload-eds-key(
        v-else-if="isEdsKey"
        @select="handleEdsKeySelect"
        @confirm="handleEdsKeyReplacement"
        button-text="Continue"
        :show-key-info="showKeyInfo"
        :key-data="keyData"
        :key-form="keyForm"
        :key-file-name="keyFileName"
        :btn-loading="loading"
        show-key-launch-buttons
      )
      v-form(
        v-else-if="isDsCreating"
        @submit.prevent.stop="handleDsKeyReplacement"
      )
        ValidationObserver(ref="form" slim)
          t-orm-fields(
            v-bind.sync="editedItem"
            :items="fields"
          )
        div(class="change-employee-eds-key__actions")
          v-btn(
            class="main-button"
            :loading="loading"
            type="submit"
        ) {{ $t('Continue') }}
      div(v-else-if="isDsExisting")
        m-form-depositsign-token(
          :show-button="false"
          :show-label="false"
          ref="depositSignForm"
        )
        div(class="change-employee-eds-key__actions")
          v-btn(
            class="main-button"
            :loading="loading"
            @click="handleExistingDsKeyReplacement"
          ) {{ $t('Continue') }}
    div(
      v-if="showHintBlock && !xsDevice && currentSignStep !== 'keyInfo'"
      class="change-employee-eds-key__right"
    )
      block-eds-iit-checkbox-hint(
        v-if="currentStep === steps.sign"
        :current-step="currentSignStep"
      )
      block-employee-form-hint(
        :currentStep="currentStep"
        v-else
      )
</template>

<script>
import Base64js from 'base64-js'
import MFormBlock from '~/components/modules/blocks/m-form-block'
import Employees from '~/modules/employees/models/Employees'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import BlockEmployeeCreatingOptions from '~/modules/employees/views/components/block-employee-creating-options'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import employeeCreating from '~/modules/employees/mixins/employeeCreating'
import BlockEmployeeFormHint from '~/modules/employees/views/components/block-employee-form-hint'
import responsive from '~/mixins/pages/responsive'
import FormEmployeeUploadEdsKey from '~/modules/employees/views/components/form-employee-upload-eds-key'
import contentDialog from '~/mixins/dialogs/contentDialog'
import MFormDepositsignToken from '~/components/modules/forms/concrete/m-form-depositsign-token'
import EdsIitCheckbox from '~/modules/eds-iit-checkbox/views/eds-iit-checkbox'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'
import BlockEdsIitCheckboxHint from '~/modules/eds-iit-checkbox/views/block-eds-iit-checkbox-hint'

export default {
  name: 'BlockChangeEmployeeEdsKey',
  components: {
    EdsIitCheckbox,
    ESvgIcon,
    BlockEmployeeCreatingOptions,
    MFormBlock,
    TOrmFields,
    BlockEmployeeFormHint,
    FormEmployeeUploadEdsKey,
    MFormDepositsignToken,
    BlockEdsIitCheckboxHint
  },
  mixins: [employeeCreating, responsive, contentDialog, edsIitCheckboxDialog],
  props: {
    item: {
      type: null,
      default: null
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    choice: null,
    loading: false,
    editedItem: {
      phoneNumber: null
    },
    currentStep: 'keyReplacementOptions',
    currentStepNumber: 1,
    totalStepsNumber: 2,
    showKeyInfo: false,
    keyData: null,
    keyForm: null,
    keyFileName: null,
    dsKeyData: null,
    edsIitCheckboxKey: 0,
    currentSignStep: 'options'
  }),
  computed: {
    model () {
      return Employees
    },
    orderModel () {
      return EmployeeOrder
    },
    isEdsKey () {
      return this.choice === this.steps.keyReplacementFormEdsKey
    },
    isDsCreating () {
      return this.choice === this.steps.keyReplacementDsCreating
    },
    isDsExisting () {
      return this.choice === this.steps.keyReplacementDsExisting
    },
    showHintBlock () {
      return this.currentStep !== this.steps.edsKeyInfo
    },
    title () {
      if (this.currentStep === this.steps.keyReplacementFormEdsKey) {
        return 'Specify a new EDS key for signing checks'
      } else if (this.currentStep === this.steps.edsKeyInfo) {
        return 'Check the read key information'
      } else if (this.currentStep === this.steps.keyReplacementDsCreating) {
        return 'Generating a DepositSign key'
      } else if (this.currentStep === this.steps.keyReplacementDsExisting) {
        return 'DepositSign data'
      }
      return 'Employees key replacement'
    },
    fields () {
      return [
        {
          model: 'phoneNumber',
          cast: val => val && val.replace(/-/g, ''),
          component: 'e-input-mask',
          provider: {
            vid: 'phoneNumber',
            name: 'Phone',
            rules: 'required|phone'
          },
          attrs: {
            label: 'Phone',
            outlined: true,
            mask: 'mobile'
          },
          fieldVal: () => this._.get(this.$User, 'phone', '')
        }
      ]
    },
    DRFOCode () {
      return this._.get(this.item, 'T1RXXXXG2S') || this._.get(this.$Organization, 'edrpou', null) || this._.get(this.$Organization, 'taxNumber', null)
    }
  },
  methods: {
    handleStepBack () {
      switch (this.currentStep) {
        case this.steps.edsKeyInfo:
          this.currentStep = this.steps.keyReplacementFormEdsKey
          this.showKeyInfo = false
          break
        case this.steps.sign:
          if (this.isEdsKey) {
            this.currentStep = this.steps.edsKeyInfo
            this.showKeyInfo = true
          } else {
            this.currentStep = this.choice
          }
          this.currentStepNumber = 1
          break
        default:
          this.handleDataClear()
          break
      }
    },
    handleDataClear () {
      this.choice = null
      this.editedItem = {
        phoneNumber: null
      }
      this.currentStep = this.steps.keyReplacementOptions
      this.keyData = null
      this.keyForm = null
      this.keyFileName = null
      this.dsKeyData = null
      this.currentStepNumber = 1
      this.totalStepsNumber = 2
      this.showKeyInfo = false
      this.currentSignStep = this.steps.options
    },
    handleOptionSelect (key) {
      if (key === this.steps.selectEdsKey) {
        this.choice = this.steps.keyReplacementFormEdsKey
        this.currentStep = this.steps.keyReplacementFormEdsKey
      } else if (key === this.steps.creatingDsKey) {
        this.choice = this.steps.keyReplacementDsCreating
        this.currentStep = this.steps.keyReplacementDsCreating
      } else if (key === this.steps.existingDsKey) {
        this.choice = this.steps.keyReplacementDsExisting
        this.currentStep = this.steps.keyReplacementDsExisting
      }
    },
    handleSignPluginStepChange (step) {
      if (step === 'options' && this.currentSignStep === 'options') {
        this.handleStepBack()
      }
      this.currentSignStep = step
    },
    handleEdsKeySelect () {
      this.currentStep = this.steps.edsKeyInfo
      this.showKeyInfo = true
    },
    goToSigning () {
      this.currentStep = this.steps.sign
      this.currentStepNumber += 1
    },
    handleSignActionError () {
      this.currentStep = this.steps.sign
      this.edsIitCheckboxKey += 1
    },
    async showSuccessDialog () {
      const title = this.isDsCreating ? 'Request sent successfully' : 'The request to DPS was successfully sent'
      const text = this.isDsCreating ? 'Your application is on verification. It can take some time.' : 'After successful registration, the status of the new cashier will change to "Active", and the old one will be deactivated'
      await this.contentDialog.open({
        component: 'block-employee-information',
        width: '560px',
        contentNoGutters: true,
        componentProps: {
          title,
          text
        }
      })
    },
    async handleSignKeyConfirmed (signInterface) {
      try {
        const { error, noReturn, edsKeyData, reportService, sign, fallbackMethod } = await this.generateSignInterfacePayloadAndErrorsCheck({
          signInterface,
          checkAuthService: true,
          createReportService: true,
          fallbackMethod: this.handleSignActionError
        })
        if (error) {
          return !noReturn ? this.handleSignActionError() : null
        }

        // Key data publicKeyId verification
        const verified = await this.edsKeyVerify({ edsKeyData, sign, fallbackMethod })
        if (!verified) {
          return null
        }

        const commonReplacementPayload = {
          HKBOS: reportService.getHKBOSField({
            type: 'keyReplacement',
            entity: this._.get(this.orderModel, 'entity'),
            sentry: this.$sentry,
            organization: this.$Organization
          }),
          T1RXXXXG1S: this._.get(this.item, 'name', null),
          T1RXXXXG4S: this.orderModel.itemTypes.head_cashier.value,
          T1RXXXXG2S: this.DRFOCode,
          HPOST: 'Директор',
          employee: `/employees/${this._.get(this.item, 'id')}`
        }

        if (!this.checkHkbos(commonReplacementPayload.HKBOS)) {
          return
        }

        let replacementPayload = {}

        if (this.isEdsKey) {
          replacementPayload = {
            ...commonReplacementPayload,
            T1RXXXXG3S: this._.get(this.keyData, 'key_id', null),
            signatureType: 'AGENT',
            ca: this._.get(this.keyData, 'certificate_info.certificate.pszIssuerCN', null),
            certificateStart: this._.get(this.keyData, 'certificate_start', null),
            certificateEnd: this._.get(this.keyData, 'certificate_end', null),
            certificateInfo: this._.get(this.keyData, 'certificate_info.certificate', null)
          }

          if (this._.get(this.keyForm, 'uploadCashierEdsKey')) {
            replacementPayload.keyFileCa = this._.get(this.keyForm, 'ca', null)
            replacementPayload.keyPassword = this._.get(this.keyForm, 'keyPassword', null)
            replacementPayload.keyFile = Base64js.fromByteArray(new Uint8Array(this._.get(this.keyForm, 'keyFile', null)))
          }
        } else if (this.isDsCreating) {
          replacementPayload = {
            ...commonReplacementPayload,
            depositSignRequest: {
              phoneNumber: this._.get(this.editedItem, 'phoneNumber', null),
              certificateEncoded: Base64js.fromByteArray(new Uint8Array(Object.values(this._.get(edsKeyData.keyData, '[0].data', {}))))
            },
            T1RXXXXG3S: null,
            signatureType: 'DEPOSITSIGN'
          }
        } else if (this.isDsExisting) {
          replacementPayload = {
            ...commonReplacementPayload,
            T1RXXXXG3S: this._.get(this.dsKeyData, 'publicKeyId', '').toLowerCase(),
            dsToken: this._.get(this.dsKeyData, 'token', null),
            dsPass: this._.get(this.dsKeyData, 'password', null),
            certificateEnd: this._.get(this.dsKeyData, 'certificateEnd', null),
            certificateStart: this._.get(this.dsKeyData, 'certificateStart', null),
            certificateInfo: this._.get(this.dsKeyData, 'certificateInfo', null),
            ca: this._.get(this.dsKeyData, 'ca', null),
            signatureType: 'DEPOSITSIGN'
          }
        }

        const createdOrder = this._.get(await this.orderModel.api().keyReplacement(replacementPayload), `entities.${this.orderModel.entity}[0]`, null)

        if (this.isEdsKey || this.isDsExisting) {
          const deletedOrder = { id: this._.get(createdOrder, 'deletedOrderId', null) }
          // Send to tax delete order
          await this.sendToTax({
            order: deletedOrder,
            reportService
          })
          // Send to tax create order
          await this.sendToTax({
            order: createdOrder,
            reportService
          })
        }

        await this.model.api().read(this._.get(this.item, 'id'))
        await this.showSuccessDialog()
      } catch (e) {
        this.$handlers.error(e, this)
        this.closeModal()
      } finally {
        this.loading = false
      }
    },
    async sendToTax ({ order, reportService } = {}) {
      const xmlResponse = await this.orderModel.api().xmlDoc(order)
      const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
      const fname = this._.get(xmlResponse, 'response.data.fname', null)
      const signData = await reportService.getEnvelopedData(xml)
      if (!signData) {
        this.$handlers.error('An error occurred while signing the document. Please try again or choose a different key', this)
        return
      }
      const taxId = this._.get(await this.orderModel.api().taxRegister(order, { signData, fname }), 'response.data.response.taxId', null)
      const encodedTaxId = new TextEncoder().encode(String(taxId))
      const signedTaxId = await reportService.getEnvelopedData(encodedTaxId, false)
      if (!signedTaxId) {
        this.$handlers.error('An error occurred while signing the document. Please try again or choose a different key', this)
        return
      }
      await this.orderModel.api().signTaxId(order, { tax_id: signedTaxId })
    },
    checkHkbos (hkbos) {
      const re = /([0-9]{10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6}|[0-9]{9})/
      if (!re.test(hkbos)) {
        this.$handlers.error(this.$t('It is not possible to find the registration number of the director of the organization. Check the EDS key with which you sign documents'), this)
        return false
      }
      if (!hkbos) {
        this.$handlers.error(this.$t('We could not retrieve data from your EDS key, please check whether it is correct or choose another EDS key'), this)
        return false
      }
      return true
    },
    async handleEdsKeyReplacement ({ form, keyData, keyFileName } = {}) {
      try {
        this.loading = true
        this.keyData = keyData
        this.keyForm = form
        this.keyFileName = keyFileName

        await this.orderModel.api().validateReplacementKey(this._.get(this.item, 'id'), {
          keyReplacementId: this._.get(keyData, 'key_id')
        })
        this.goToSigning()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async handleDsKeyReplacement () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        this.goToSigning()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async handleExistingDsKeyReplacement () {
      if (!this.$refs?.depositSignForm) {
        return
      }
      try {
        this.loading = true
        const data = await this.$refs.depositSignForm.setToken()
        if (!data || !data.token || !data.password || !data.publicKeyId) {
          return
        }
        this.dsKeyData = data
        this.goToSigning()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.change-employee-eds-key {
  display: flex;

  &__title {
    font-weight: 700;
    color: $btn-secondary-color;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 32px;
    }
  }

  &__back-arrow {
    transform: rotate(180deg);
    margin-right: 5px;
  }

  &__left {
    padding: 40px 12px 26px;
    width: 100%;

    @media (min-width: map-get($breakpoints, 'sm')) {
      padding: 40px 32px 30px;
    }
  }

  &__right {
    width: 100%;
    max-width: 340px;
  }

  &__stepper {
    border-radius: 6px;
    background: #F0F1F2;
    padding: 2px 6px;
    color: $btn-secondary-color;
    font-size: 11px;
    display: inline-block;
    margin-bottom: 8px;
    line-height: 16px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    .main-button {
      width: 100%;

      @media (min-width: map-get($breakpoints, 'xs')) {
        max-width: 180px;
      }
    }
  }
}
</style>
