<template lang="pug">
  div(class="employee-creating-options")
    div(class="employee-creating-options__main-option")
      div(
        v-if="mainOption.recommended"
        class="employee-creating-options__other-options-card-free"
      ) {{ $t('Free') }}
      h3(class="employee-creating-options__main-option-title") {{ $t(mainOption.title) }}
      p(class="employee-creating-options__main-option-text") {{ $t(mainOption.text) }}
      v-btn(
        @click="mainOption.onClick(mainOption.key)"
        :loading="mainOption.key === steps.creatingDsKey ?  dsRequestLoading : false"
        class="employee-creating-options__main-option-btn"
        dark
      ) {{ $t('Add') }}

    div(class="employee-creating-options__manual-option-wrap")
      p(class="employee-creating-options__subtitle") {{ $t('Other options') }}:
      p(
        v-if="manualOption.visible && !xsDevice"
        class="employee-creating-options__manual-option"
        @click="manualOption.onClick(manualOption.key)"
      )
        | {{ $t(manualOption.title) }}
        i &#9997;
    div(class="employee-creating-options__other-options")
      div(
        v-for="item in otherOptions"
        v-if="item.visible"
        class="employee-creating-options__other-options-card"
        @click="item.onClick(item.key)"
      )
        div(
          v-if="item.recommended"
          class="employee-creating-options__other-options-card-recommend"
        ) {{ $t('Recommended') }}
        p(class="employee-creating-options__other-options-card-title") {{ $t(item.title) }}
        v-btn(
          icon
          small
        )
          e-svg-icon(:size="xsDevice ? 'xsm' : 'sm'") arrow-next
      p(
        v-if="manualOption.visible && xsDevice"
        class="employee-creating-options__manual-option"
        @click="manualOption.onClick(manualOption.key)"
      )
        | {{ $t(manualOption.title) }}
        i &#9997;
</template>

<script>
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'
import employeeCreating from '~/modules/employees/mixins/employeeCreating'

export default {
  name: 'BlockEmployeeCreatingOptions',
  components: {
    ESvgIcon
  },
  mixins: [responsive, employeeCreating],
  props: {
    showManually: {
      type: Boolean,
      default: true
    },
    checkDsRequestAllow: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    dsRequestLoading: false
  }),
  computed: {
    mainOption () {
      return {
        key: this.steps.creatingDsKey,
        title: 'Generate a DepositSign key',
        text: 'Create a free EDS key in a few steps using the DepositSign cloud service.',
        onClick: key => this.handleDsClick(key),
        visible: true,
        recommended: true
      }
    },
    manualOption () {
      return {
        key: this.steps.manualEmployeeForm,
        title: 'Enter certificate data manually',
        onClick: key => this.handleClick(key),
        visible: this.showManually
      }
    },
    otherOptions () {
      return [
        {
          key: this.steps.selectEdsKey,
          title: 'Through the EDS key',
          onClick: key => this.handleClick(key),
          visible: true
        },
        {
          key: this.steps.existingDsKey,
          title: 'A valid DepositSign key',
          onClick: key => this.handleClick(key),
          visible: true
        }
      ]
    }
  },
  methods: {
    handleClick (key) {
      this.$emit('select', key)
    },
    async handleDsClick (key) {
      if (!this.checkDsRequestAllow) {
        this.handleClick(key)
      } else {
        try {
          this.dsRequestLoading = true
          const allowedDsRequest = this._.get(await EmployeeOrder.api().allowedDsRequest(), 'response.data.allowed', false)
          if (allowedDsRequest) {
            this.handleClick(key)
          } else {
            this.$handlers.error(this.$t('Unfortunately, the number of cloud KEPs from Deposit Sign should not exceed the number of Active cash registers'), this)
          }
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.dsRequestLoading = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.employee-creating-options {
  &__main-option {
    background: rgba(67, 204, 180, 0.08);
    border-radius: 12px;
    padding: 16px;
    position: relative;
  }

  &__main-option-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px;
    line-height: 24px;
    color: $btn-secondary-color;
  }

  &__main-option-text {
    font-size: 14px;
    margin-bottom: 24px;
    color: rgba(22, 27, 37, 0.75);
  }

  &__main-option-btn {
    width: 100%;

    @media (min-width: map-get($breakpoints, 'xs')) {
      max-width: 180px;
    }
  }

  &__subtitle {
    font-size: 14px;
    color: $btn-secondary-color;
    line-height: 20px;
    margin: 0;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__other-options {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  &__other-options-card {
    width: 100%;
    border-radius: 12px;
    border: 2px solid $btn-secondary-color;
    padding: 15px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: .3s;
    position: relative;

    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }

    @media (min-width: map-get($breakpoints, 'md')) {
      width: calc(50% - 8px);
    }
  }

  &__other-options-card-title {
    font-size: 14px;
    margin: 0;
    color: #000;
  }

  &__other-options-card-recommend {
    background: #43CCB4;
    padding: 4px 8px;
    border-radius: 7px;
    color: #fff;
    font-size: 10px;
    line-height: 14px;
    position: absolute;
    top: 0;
    left: 16px;
    transform: translateY(-50%);
  }

  &__other-options-card-free {
    background: $color-warning;
    padding: 4px 8px;
    border-radius: 7px;
    color: #fff;
    font-size: 10px;
    line-height: 14px;
    position: absolute;
    top: 0;
    left: 16px;
    transform: translateY(-50%);
  }

  &__manual-option {
    margin: 0;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    color: #000;

    i {
      font-size: 16px;
      margin-left: 5px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__manual-option-wrap {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    margin: 24px 0 16px;
    flex-wrap: wrap;

    @media (min-width: map-get($breakpoints, 'sm')) {
      margin: 30px 0 16px;
    }
  }
}
</style>
